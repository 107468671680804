/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser'
import { Module } from 'vuex'
import axios from '~plugins/axios'

import { Survey, SurveyParams } from '~models/survey'

import { RootState, ModuleContext } from '~plugins/store'

export interface State {
  surveys?: Survey[]
}

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    surveys: undefined,
  },
  actions: {
    async fetchAll({ commit }: ModuleContext<State>): Promise<void> {
      try {
        const { data } = await axios.get('surveys')
        commit('SET_SURVEYS', { data })
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    async create({ commit }: ModuleContext<State>, survey: SurveyParams): Promise<void> {
      const { data } = await axios.post('surveys', survey)
      commit('APPEND_NEW_SURVEY', { data })
      return data
    },
    async update({ commit }: ModuleContext<State>, survey: Survey): Promise<void> {
      const { data } = await axios.put(`surveys/${survey.id}`, survey)
      commit('UPDATE_SURVEY', { data })
      return data
    },
    async delete({ commit }: ModuleContext<State>, id: string): Promise<void> {
      await axios.delete(`surveys/${id}`)
      commit('DELETE_SURVEY', id)
    },
  },
  mutations: {
    SET_SURVEYS(state: State, { data }: { data: Survey[] }): void {
      state.surveys = data
    },
    APPEND_NEW_SURVEY(state: State, { data }: { data: Survey }): void {
      // Surveys are sorted in desc order
      state.surveys = [
        data,
        ...(state.surveys || []),
      ]
    },
    UPDATE_SURVEY(state: State, { data }: { data: Survey }): void {
      if (state.surveys === undefined) {
        return
      }

      state.surveys = Array.from(state.surveys, (existingSurveys) => {
        if (existingSurveys.id === data.id) {
          return {
            ...existingSurveys,
            ...data,
          }
        }

        return existingSurveys
      })
    },
    DELETE_SURVEY(state: State, id: string): void {
      if (state.surveys === undefined) {
        return
      }

      state.surveys = [
        ...state.surveys.filter((survey: Survey) => survey.id !== id),
      ]
    },
  },
}

export default store
