<template>
  <div>
    <label
      class="block text-sm my-2 leading-none "
    >
      {{ label }}
    </label>
    <input
      v-model="inputValue"
      v-bind="$attrs"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :class="errorClass"
      :maxlength="maxlength"
      :tabindex="tabindex"
      class="transition duration-75 ease-in-out  w-full h-9 rounded-md px-3 text-sm border border-gray-200 hover:border-blue-border-200 focus:outline-none focus:bg-white focus:border-blue"
      v-on="inputListeners"
    >
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    maxlength: {
      type: String,
      default: '5000',
    },
    tabindex: {
      type: String,
      default: '',
    },
  },

  computed: {
    inputValue: {
      get(): string | number {
        return this.value
      },
      set(val: string | number): void {
        this.$emit('input', val)
      },
    },
    errorClass(): string {
      if (this.error) {
        return 'border-red'
      }

      return 'border-blue-border-100'
    },
    inputListeners(): Record<string, Function | Function[]> {
      return {
        ...this.$listeners,
        input: (e: any): void => {
          this.$emit('input', e.target.value)
        },
      }
    },
  },
})
</script>
