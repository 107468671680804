<template>
  <header class="px-16 border-b border-blue-border-100">
    <div class="container flex justify-between items-center h-15">
      <div>
        <RouterLink
          :to="{ name: 'results'}"
          tag="div"
          class="transition duration-75 view cursor-pointer p-1 mr-10"
        >
          <svg width="87" height="36" viewBox="0 0 87 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 11.02v13.96a4.04 4.04 0 002.02 3.498l12.091 6.98a4.038 4.038 0 004.04 0l12.088-6.98a4.04 4.04 0 002.02-3.497V11.019a4.04 4.04 0 00-2.02-3.497L18.15.542a4.04 4.04 0 00-4.039 0L2.02 7.521A4.04 4.04 0 000 11.019z" fill="#0097FF"/><path d="M6.637 25.228l12.02-.017a2.85 2.85 0 002.457-1.425l5.995-10.42a1.805 1.805 0 00-1.57-2.705l-12.019.016a2.852 2.852 0 00-2.456 1.426L5.069 22.514a1.806 1.806 0 001.568 2.714z" fill="#fff"/><path d="M16.13 22.258a4.258 4.258 0 100-8.516 4.258 4.258 0 000 8.516zM40.78 11.647h8.496v1.995h-5.969v3.726h4.78v1.9h-4.78v3.973H49.6v1.996h-8.82v-13.59zM54.221 20.139L50.99 15.29h2.66l2.073 3.213 2.053-3.213h2.414l-3.213 4.681 3.564 5.265h-2.69l-2.318-3.592-2.376 3.593h-2.395l3.46-5.1zM61.027 22.48c0-2.318 2.243-3.154 5.094-3.154h1.236v-.514c0-1.33-.456-1.995-1.806-1.995-1.188 0-1.787.57-1.9 1.558h-2.225c.19-2.319 2.034-3.288 4.258-3.288s3.973.912 3.973 3.63v6.52h-2.262v-1.216c-.646.855-1.52 1.406-3.041 1.406-1.825 0-3.327-.874-3.327-2.946zm6.33-.712v-.988h-1.188c-1.749 0-2.87.399-2.87 1.615 0 .837.456 1.388 1.596 1.388 1.417-.01 2.462-.75 2.462-2.024v.009zM71.781 10.696h2.303v8.967l3.564-4.372h2.509l-3.859 4.562 4.182 5.38h-2.661l-3.735-4.881v4.885H71.78V10.696zM82.35 22.462v-5.436h-1.33v-1.73h1.33V13.11h2.3v2.186h2.186v1.73H84.65v5.227c0 .875.418 1.274 1.14 1.274.383.013.765-.052 1.122-.19v1.824a4.423 4.423 0 01-1.559.238c-1.977.01-3.003-1.055-3.003-2.937z" fill="#000"/></svg>
        </RouterLink>
      </div>

      <nav class="navigation text-sm w-1/2 absolute left-0 right-0 mx-auto">
        <ul class="flex items-center justify-center">
          <li class="mx-5 leading-none">
            <RouterLink
              :to="{ name: 'results'}"
              class="inline-flex items-center font-medium"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2 outline-none'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.167 2.5h2.5c.92 0 1.666.746 1.666 1.667v2.5c0 .92-.746 1.666-1.666 1.666h-2.5c-.92 0-1.667-.746-1.667-1.666v-2.5c0-.92.746-1.667 1.667-1.667zm0 4.167h2.5v-2.5h-2.5v2.5zM15.833 2.5h-2.5c-.92 0-1.666.746-1.666 1.667v2.5c0 .92.746 1.666 1.666 1.666h2.5c.92 0 1.667-.746 1.667-1.666v-2.5c0-.92-.746-1.667-1.667-1.667zm-2.5 4.167h2.5v-2.5h-2.5v2.5zm-5 6.666c0-.92-.746-1.666-1.666-1.666h-2.5c-.92 0-1.667.746-1.667 1.666v2.5c0 .92.746 1.667 1.667 1.667h2.5c.92 0 1.666-.746 1.666-1.667v-2.5zm-4.166 2.5h2.5v-2.5h-2.5v2.5zm11.666-4.166h-2.5c-.92 0-1.666.746-1.666 1.666v2.5c0 .92.746 1.667 1.666 1.667h2.5c.92 0 1.667-.746 1.667-1.667v-2.5c0-.92-.746-1.666-1.667-1.666zm-2.5 4.166h2.5v-2.5h-2.5v2.5z" fill="#8592A3"/></svg>
              Results
            </RouterLink>
          </li>
          <li class="mx-5 leading-none">
            <RouterLink
              :to="{ name: 'surveys' }"
              class="inline-flex items-center font-medium"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2 outline-none'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 7h-17a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h17a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm.5 5.5v-1a.5.5 0 00-.5-.5h-17a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h17a.5.5 0 00.5-.5zm0 5v1a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h17a.5.5 0 01.5.5z" fill="#8592A3"/></svg>
              Surveys
            </RouterLink>
          </li>
          <li class="mx-5 leading-none">
            <RouterLink
              :to="{ name: 'widget' }"
              class="inline-flex items-center font-medium"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-2 outline-none'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 3a1 1 0 00-1-1H7a1 1 0 00-1 1v1h12V3zM6 6h12a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2V8a2 2 0 012-2zm12 10V8H6v8h12zM7 22a1 1 0 01-1-1v-1h12v1a1 1 0 01-1 1H7z" fill="#8592A3"/></svg>
              Widget
            </RouterLink>
          </li>
        </ul>
      </nav>

      <div class="navigation text-xs leading-none items-center relative">
        <div v-if="isTrialing" class="mr-4 inline-block">
          <span v-if="trialDaysLeft > 0" class="inline-flex text-text">{{ trialDaysLeft }} days left in trial</span>
          <span v-else class="inline-flex text-red">Trial expired</span>

          <span class="text-gray-500"> · </span>
          <RouterLink
            :to="{ name: 'billing' }"
            class="inline-flex text-blue"
          >
            Subscribe
          </RouterLink>
        </div>

        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'transition duration-75 ease-in-out outline-none cursor-pointer inline-block transform scale-95 hover:scale-100'" @click.stop="showMenu = !showMenu" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="32" height="32" rx="16" fill="#E5E9F1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.002 9.6a3.2 3.2 0 100 6.401 3.2 3.2 0 000-6.401zm5.162 9.328l1.153 2.312a.8.8 0 01-.713 1.16H10.4a.8.8 0 01-.712-1.16l1.152-2.312a2.4 2.4 0 012.153-1.328h6.018a2.4 2.4 0 012.153 1.328z" fill="#3E4856"/></svg>
        <div
          v-on-clickaway="hideMenu"
          :class="{hidden: !showMenu}"
          class="dropdown absolute py-3 w-40 right-0 mt-2 -mr-1 bg-white rounded-md shadow-2xl text-sm z-20 border border-blue-border-100"
        >
          <div class="cursor-pointer py-2 px-3 font-normal hover:text-blue tranisiton duration-75" @click.prevent="openSettings">
            Personal
          </div>
          <div class="cursor-pointer py-2 px-3 font-normal hover:text-blue tranisiton duration-75" @click.prevent="openSettingsBilling">
            Billing
          </div>
          <div class="cursor-pointer pt-2 pb-4 px-3 font-normal hover:text-blue tranisiton duration-75" @click.prevent="openSettingsTeam">
            Team
          </div>
          <!--div class="cursor-pointer py-2 px-3 font-normal hover:text-blue tranisiton duration-75" @click.prevent="openSettings">
            Settings
          </div-->
          <div class="cursor-pointer pt-4 pb-2 px-3 font-normal hover:text-red tranisiton duration-75 border-t border-blue-border-100" @click.prevent="logout">
            Logout
          </div>
        </div>
        <!-- TODO - Replace hard-coded avatar with actual link - it's broken for me so it was hard to style it -->
        <!--img
          :src="avatarUrl"
          alt="Menu"
          class="w-7.5 h-7.5 rounded-full outline-none cursor-pointer"
          @click.stop="showMenu = !showMenu"
        -->
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import Vue from 'vue'
import { mixin as clickaway } from 'vue-clickaway'

import { BillingStatus } from '~models/billing-status'

export default Vue.extend({
  mixins: [
    clickaway,
  ],

  data() {
    return {
      showMenu: false,
    }
  },

  computed: {
    avatarUrl(): string {
      return this.$store.state.auth.user.avatarUrl
    },
    trialDaysLeft(): number {
      return this.$store.state.billing?.billing?.trialDaysLeft || 0
    },
    isTrialing(): boolean {
      return this.$store.getters['billing/billingStatus'] === BillingStatus.TRIALING
    },
  },

  methods: {
    openSettings(): void {
      this.showMenu = false
      this.$router.push({ name: 'personal-settings' })
    },
    openSettingsBilling(): void {
      this.showMenu = false
      this.$router.push({ name: 'billing' })
    },
    openSettingsTeam(): void {
      this.showMenu = false
      this.$router.push({ name: 'team-settings' })
    },
    hideMenu(): void {
      this.showMenu = false
    },
    logout(): void {
      this.$store.dispatch('auth/logout')
    },
  },
})
</script>

<style lang="scss" scoped>
.navigation {
  a:hover {
    color: #0AAFFF;
    transition: all 75ms;
    svg {
      path {
        fill: #0AAFFF;
        transition: all 75ms;
      }
    }
  }

  .router-link-active {
    color: #0AAFFF;

    svg {
      path {
        fill: #0AAFFF;
      }
    }
  }
}
</style>
