import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { ENVIRONMENTS, getCurrentEnvironment } from '~utils/environment'

export default function useSentry(Vue: any = null): void {
  const environment = getCurrentEnvironment()

  const config = {
    dsn: 'https://fe002801b79a4490a87ce014d9b3411c@o390477.ingest.sentry.io/5233693',
    beforeSend(event: any, hint: { originalException?: any } = {}) {
      // eslint-disable-next-line no-console
      console.error(hint.originalException)

      if (environment === ENVIRONMENTS.DEVELOPMENT) {
        return null // Don't log errors to Sentry in development
      }

      if (hint.originalException.response) {
        return null // Don't log Network Errors to Sentry
      }

      return event
    },
    environment,
  }

  if (Vue) {
    Object.assign(config, {
      integrations: [
        new Integrations.Vue({
          Vue,
          logErrors: environment === ENVIRONMENTS.DEVELOPMENT,
        }),
      ],
    })
  }

  Sentry.init(config)
}
