<template>
  <SettingsTile :loading="!product">
    <template #icon>
      <svg width="54" height="60" viewBox="0 0 54 60" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 18.366v23.269a6.732 6.732 0 003.366 5.829l20.152 11.634a6.733 6.733 0 006.732 0L50.4 47.464a6.733 6.733 0 003.366-5.83V18.367a6.732 6.732 0 00-3.366-5.83L30.25.902a6.732 6.732 0 00-6.732 0L3.366 12.536A6.732 6.732 0 000 18.366z" fill="#0097FF"/><path d="M11.049 42.047l20.033-.027a4.752 4.752 0 004.095-2.376l9.991-17.365a3.009 3.009 0 00-2.617-4.51l-20.03.027a4.752 4.752 0 00-4.095 2.376l-9.99 17.353a3.01 3.01 0 002.613 4.522z" fill="#fff"/><path d="M26.881 37.096a7.096 7.096 0 100-14.193 7.096 7.096 0 000 14.193z" fill="#000"/></svg>
    </template>

    <p class="leading-none pb-2">
      {{ productName }}
    </p>
    <p class="leading-none text-sm text-navy-300">
      {{ price | formatMoneyValue }} per {{ productInterval }}
    </p>

    <template #action>
      <Spinner v-if="loading" />
      <Button
        v-if="billingStatus !== BillingStatus.PAYING && !loading && showSubscribeButton"
        class="btn-primary px-4 py-3"
        @click.prevent="subscribe"
      >
        Subscribe
      </Button>

      <div
        v-if="billingStatus === BillingStatus.PAYING"
        class="ml-auto"
      >
        <a class="text-sm text-red cursor-pointer" @click="isCancelModalOpen = true">Cancel</a>
      </div>
    </template>

    <Modal
      v-if="isCancelModalOpen"
      title="Confirm cancellation"
      cancel-button-text="Cancel"
      :show-cancel-button="true"
      @close="isCancelModalOpen = false"
    >
      <template #default>
        <p class="text-sm leading-snug">
          Are you sure you want to cancel your plan? We will stop showing any surveys and you'll loose access to your data in Exakt after {{ parseDate(currentPeriodEnd) }}.
        </p>
      </template>

      <template #action>
        <Spinner v-if="loading" />
        <Button
          v-else
          variant="danger"
          @click="unsubscribe"
        >
          Confirm cancelation
        </Button>
      </template>
    </Modal>

    <Modal
      v-if="isCreditCardModalOpen"
      :title="`Credit card`"
      cancel-button-text="Cancel"
      :show-cancel-button="false"
      @close="isCreditCardModalOpen = false"
    >
      <p class="text-sm leading-snug mb-6">
        Add your credit card details to subscribe.
      </p>

      <CreditCardForm
        action-button-text="Subscribe"
        @close="isCreditCardModalOpen = false"
        @cardSuccessfullyUpdated="updateProduct"
      />
    </Modal>
  </SettingsTile>
</template>

<script lang="ts">
import Vue from 'vue'
import { parseJSON, format } from 'date-fns'

import formatMoneyValue from '~utils/format-money-value'

import Button from '~components/button/button.vue'
import SettingsTile from '~components/settings-tile/settings-tile.vue'
import Modal from '~components/modal/modal.vue'
import Spinner from '~components/spinner/spinner.vue'
import CreditCardForm from './credit-card-form.vue'

import { Product } from '~models/product'
import { BillingStatus } from '~models/billing-status'
import { BillingInterval } from '~models/billing-interval'

export default Vue.extend({
  components: {
    Button,
    SettingsTile,
    Modal,
    Spinner,
    CreditCardForm,
  },

  filters: {
    formatMoneyValue,
  },

  props: {
    showSubscribeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      BillingStatus,
      isCancelModalOpen: false,
      isCreditCardModalOpen: false,
    }
  },

  computed: {
    loading(): boolean {
      return this.$store.getters['billing/loading']
    },
    currentPeriodEnd(): string {
      return this.$store.getters['billing/currentPeriodEnd']
    },
    billingStatus(): BillingStatus | undefined {
      return this.$store.getters['billing/billingStatus']
    },
    product(): Product {
      if (this.billingStatus === BillingStatus.PAYING) {
        return this.$store.getters['billing/currentProduct']
      }

      return this.$store.state.billing?.billing?.allProducts?.filter((product: Product) => product.interval === BillingInterval.MONTH)?.[0]
    },
    price(): number | undefined {
      return this.product?.usdCentsPrice
    },
    productName(): string | undefined {
      return this.product?.name
    },
    productInterval(): string | undefined {
      return this.product?.interval
    },
  },

  methods: {
    async unsubscribe() {
      try {
        await this.$store.dispatch('billing/cancelBilling')
      } catch (error) {
        // show error
      } finally {
        this.isCancelModalOpen = false
      }
    },
    subscribe() {
      // If needed, have the user add a card first
      if (this.$store.getters['billing/needsCreditCardUpdate']) {
        this.isCreditCardModalOpen = true

        return
      }

      this.updateProduct()
    },
    async updateProduct() {
      try {
        await this.$store.dispatch('billing/updateProduct', this.product)
        await this.$store.dispatch('billing/fetchInvoices')
      } catch (error) {
        // show error
      } finally {
        this.isCreditCardModalOpen = false
      }
    },
    parseDate(date: string) {
      return date ? format(parseJSON(date), 'dd LLLL yyyy') : ''
    },
  },
})
</script>
