<template>
  <div>
    <div class="mb-8 flex items-center justify-between">
      <h1 class="text-3xl font-medium">
        Settings
      </h1>
    </div>
    <ul class="flex my-8">
      <!--li class="relative font-medium cursor-pointer mr-6">
        <RouterLink :to="{ name: 'business-settings' }">
          Business
        </RouterLink>

        <div v-if="currentRouteName === 'business-settings'" class="absolute left-0 bottom-0 -mb-2 w-full h-0 border-t-2 border-blue" />
      </li-->
      <li class="text-sm relative cursor-pointer mr-6 hover:text-blue transition duration-75 ease-in-out">
        <RouterLink :to="{ name: 'personal-settings' }">
          Personal
        </RouterLink>
        <div v-if="currentRouteName === 'personal-settings'" class="absolute left-0 bottom-0 -mb-2 w-full h-0 border-t border-blue" />
      </li>
      <li class="text-sm relative cursor-pointer mr-6 hover:text-blue transition duration-75 ease-in-out" :class="{ 'text-blue': currentRouteName === 'billing' }">
        <RouterLink :to="{ name: 'billing' }">
          Billing
        </RouterLink>
        <div v-if="currentRouteName === 'billing'" class="absolute left-0 bottom-0 -mb-2 w-full h-0 border-t border-blue" />
      </li>
      <li class="text-sm relative cursor-pointer hover:text-blue transition duration-75 ease-in-out">
        <RouterLink :to="{ name: 'team-settings' }">
          Team
        </RouterLink>
        <div v-if="currentRouteName === 'team-settings'" class="absolute left-0 bottom-0 -mb-2 w-full h-0 border-t border-blue" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  @apply text-blue
}
</style>
