<template>
  <div class="loader" />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
})
</script>

<style lang="scss" scoped>
.loader {
  border: 4px solid #E5E9F1;
  border-top: 4px solid #0AAFFF;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
