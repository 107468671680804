<template>
  <div v-if="!widget" class="flex justify-center items-center h-screen">
    <Spinner />
  </div>
  <div v-else>
    <div v-if="showChangesSavedNotice" class="absolute left-0 mt-8 right-0 mx-auto py-3 w-75 text-center rounded-6px text-sm bg-blue text-white shadow">
      Changes saved successfully
    </div>

    <div class="px-16 py-10">
      <div class="container">
        <h1 class="text-3xl mb-4 font-medium">
          Widget
        </h1>

        <ul class="flex my-8">
          <li
            class="relative text-sm mr-6 cursor-pointer hover:text-blue transition duration-75 ease-in-out"
            :class="{'text-blue': activeTab === ActiveTab.Design}"
            @click="activeTab = ActiveTab.Design"
          >
            Design
            <div v-if="activeTab === ActiveTab.Design" class="absolute left-0 bottom-0 -mb-2 w-full h-0 border-t border-blue" />
          </li>
          <li
            class="relative text-sm cursor-pointer hover:text-blue transition duration-75 ease-in-out"
            :class="{'text-blue': activeTab === ActiveTab.Script}"
            @click="activeTab = ActiveTab.Script"
          >
            Script
            <div v-if="activeTab === ActiveTab.Script" class="absolute left-0 bottom-0 -mb-2 w-full h-0 border-t border-blue" />
          </li>
        </ul>

        <div v-if="activeTab === ActiveTab.Design">
          <!-- this is DesignTab parent div -->
          <div class="flex justify-between">
            <div class="w-4/12">
              <form action="">
                <div class="mb-6">
                  <FormInput
                    v-model="widget.primaryTextColor"
                    type="text"
                    label="Text color"
                    maxlength="7"
                  />
                </div>
                <div class="mb-6">
                  <FormInput
                    v-model="widget.secondaryTextColor"
                    type="text"
                    label="Muted text color"
                    maxlength="7"
                  />
                </div>
                <div class="mb-6">
                  <FormInput
                    v-model="widget.borderRadius"
                    type="text"
                    label="Button radius"
                  />
                </div>
                <div class="py-2">
                  <p class="text-sm font-medium">
                    Primary button
                  </p>
                </div>
                <div class="flex mb-6">
                  <div class="w-1/2 pr-2">
                    <FormInput
                      v-model="widget.primaryButtonBackgroundColor"
                      type="text"
                      label="Background color"
                      maxlength="7"
                    />
                  </div>
                  <div class="w-1/2 pl-2">
                    <FormInput
                      v-model="widget.primaryButtonTextColor"
                      type="text"
                      label="Text color"
                      maxlength="7"
                    />
                  </div>
                </div>
                <div class="flex mb-6">
                  <div class="w-1/2 pr-2">
                    <FormInput
                      v-model="widget.primaryButtonHoverBackgroundColor"
                      type="text"
                      label="Hover background color"
                      maxlength="7"
                    />
                  </div>
                  <div class="w-1/2 pl-2">
                    <FormInput
                      v-model="widget.primaryButtonHoverTextColor"
                      type="text"
                      label="Hover text color"
                      maxlength="7"
                    />
                  </div>
                </div>
                <div class="py-2">
                  <p class="text-sm font-medium">
                    Secondary button
                  </p>
                </div>
                <div class="flex mb-6">
                  <div class="w-1/2 pr-2">
                    <FormInput
                      v-model="widget.secondaryButtonBackgroundColor"
                      type="text"
                      label="Background color"
                      maxlength="7"
                    />
                  </div>
                  <div class="w-1/2 pl-2">
                    <FormInput
                      v-model="widget.secondaryButtonTextColor"
                      type="text"
                      label="Text color"
                      maxlength="7"
                    />
                  </div>
                </div>
                <div class="flex mb-6">
                  <div class="w-1/2 pr-2">
                    <FormInput
                      v-model="widget.secondaryButtonHoverBackgroundColor"
                      type="text"
                      label="Hover background color"
                      maxlength="7"
                    />
                  </div>
                  <div class="w-1/2 pl-2">
                    <FormInput
                      v-model="widget.secondaryButtonHoverTextColor"
                      type="text"
                      label="Hover text color"
                      maxlength="7"
                    />
                  </div>
                </div>

                <!-- Button should only show if any input is edited -->
                <div class="pb-6 pt-4">
                  <Button
                    class="btn-primary px-5 py-3"
                    @click.prevent="updateWidget"
                  >
                    Save changes
                  </Button>
                </div>
              </form>
            </div>

            <WidgetPreview :survey="DEFAULT_SURVEY" :widget="widget" />
          </div>
        </div>

        <div v-if="activeTab === ActiveTab.Script">
          <!-- this is ScriptTab parent div -->
          <div class="pb-2">
            <p class="text-l font-medium">
              Embed widget to your website or web app
            </p>
          </div>

          <div v-if="false" class="py-2 px-2">
            Exakt widget has been installed successfully.
          </div>

          <div class="w-2/5 mt-2 mb-8 block">
            <p>Copy the snippet into your code inside the &lt;/body&gt; tag. Swap the email address from the example. That's it!</p>
          </div>

          <div class="bg-bg-100 p-6 rounded-md border border-blue-border-100">
            <code class="text-xs text-navy-800">
              <span><</span>script<span>></span><br>
              (function(e,x,a,k,t){e['exakt']=e['exakt']||function(){
              (e['exakt'].q=e['exakt'].q||[]).push([].slice.call(arguments));};
              k=x.createElement('script');t=x.getElementsByTagName('script')[0];
              k.async=1;k.src=a;t.parentNode.insertBefore(k,t);})(window,document,'https://embed.exakt.io/dist/embed.js');<br>
              <br>
              // Replace <strong>"your-customer@example.com"</strong> with any identifier (e.g. email) of your user
              <br>
              exakt('configure', '{{ widget.apiKeyPublic }}', <strong>'your-customer@example.com'</strong>);<br>
              <span></</span>script<span>></span><br>
            </code>
          </div>

          <!-- hiding for now, copy to clipboard isn't straightforward -->
          <div v-if="false" class="mt-2">
            <Button
              variant="outline"
              class="flex items-center"
            >
              <div class="flex-shrink-0 mr-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.667v-8c0-.737-.597-1.334-1.333-1.334h-8c-.737 0-1.334.597-1.334 1.334v8c0 .736.597 1.333 1.334 1.333h8c.736 0 1.333-.597 1.333-1.333zm-9.333-8h8v8h-8v-8zM13.333 12V4c.737 0 1.334.597 1.334 1.333V12A2.667 2.667 0 0112 14.667H5.333A1.333 1.333 0 014 13.333h8c.736 0 1.333-.597 1.333-1.333z" fill="#8592A3"/></svg>
              </div>
              Copy to clipboard
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'

import Vue from 'vue'

import FormInput from '~components/form/input.vue'
import Button from '~components/button/button.vue'
import Spinner from '~components/spinner/spinner.vue'
import WidgetPreview from '~components/widget-preview/widget-preview.vue'

import { DEFAULT_SURVEY } from '~pages/survey/survey.vue'

import { Widget } from '~models/widget'

enum ActiveTab {
  Design,
  Script,
}

export default Vue.extend({
  components: {
    Button,
    FormInput,
    Spinner,
    WidgetPreview,
  },

  data(): {
    ActiveTab: typeof ActiveTab,
    activeTab: ActiveTab,
    widget?: Widget,
    showChangesSavedNotice: boolean,
    DEFAULT_SURVEY: typeof DEFAULT_SURVEY,
  } {
    return {
      ActiveTab,
      activeTab: ActiveTab.Design,
      widget: undefined,
      showChangesSavedNotice: false,
      DEFAULT_SURVEY,
    }
  },

  async created(): Promise<void> {
    if (this.$store.state.widget?.widget === undefined) {
      await this.$store.dispatch('widget/fetch')
    }
    this.widget = this.$store.state.widget?.widget
  },

  methods: {
    async updateWidget(): Promise<void> {
      try {
        this.$store.dispatch('widget/update', this.widget)
        this.widget = this.$store.state.widget?.widget
        this.showChangesSavedNotice = true
        setTimeout(() => {
          this.showChangesSavedNotice = false
        }, 3000)
        window.scrollTo(0, 0)
      } catch (err) {
        Sentry.captureException(err)
      }
    },
  },
})
</script>

<style scoped>

.spinner {
  position: absolute;
  top: 7px;
  left: 7px;
}

@media (max-width: 767px) {
  #exaktWidget {
    bottom: 10px;
  }
}

#exaktWidget {
  margin: 40px auto;
  padding: 0 10px;
  max-width: 550px;
  width: 100%;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.widget-stage {
  background: white;
  -webkit-box-shadow: 0px 10px 90px rgba(28, 30, 36, 0.15);
  box-shadow: 0px 10px 90px rgba(28, 30, 36, 0.15);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.widget-visibility-controller {
  margin-top: 30px;
  display: none;
}

.widget-visibility-controller .controller {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #FFFFFF;
  position: relative;
  -webkit-box-shadow: 0px 4px 40px rgba(28, 30, 36, 0.15);
  box-shadow: 0px 4px 40px rgba(28, 30, 36, 0.15);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.widget-visibility-controller .close svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  opacity: 0.6;
  transition: all 150ms;
}

.widget-visibility-controller .close:hover svg {
  opacity: 1;
}

.slide-top-enter-active {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-top-leave-active {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
	animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .feedbackSelections {
    padding: 20px 15px 40px;
  }

  .feedbackComment {
    padding: 20px;
  }

  .feedbackCompleted {
    padding-top: 50px;
  }
}

.exaktContentContainer {
  height: 170px;
}

.exaktLinkContainer {
  padding: 12px 0px 15px;
  background: #F9FAFC;
  border-radius: 0px 0px 8px 8px;
}

.exaktLinkContainer p {
  text-align: center;
  margin: 0;
}

.exaktLinkContainer a {
  font-size: 12px;
  color: #3E4856;
  text-decoration: none;
  padding: 6px 8px;
  border-radius: 6px;
  transition: all 150ms;
}

.exaktLinkContainer a:hover {
  background: rgba(10, 175, 255, 0.1);
  color: #1F2D3F;
}

.exaktLinkContainer a:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 151, 255, 0.3);
}

.exaktLinkContainer img {
  vertical-align: bottom;
  margin-right: 8px;
  width: 14px;
}

.center {
  margin: 0 auto;
}

.exaktLogo {
  display: inline-block;
  vertical-align: middle;
}

.feedbackSelections {
  padding: 40px 20px 40px;
}

.feedbackSelections .headline {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding-bottom: 20px;
}

.feedbackComment {
  padding: 30px 30px 20px;
}

.feedbackComment textarea {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  border: 0;
  resize: none;
  line-height: 1.35;
  color: #1F2D3F;
  font-size: 14px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.feedbackComment textarea:focus {
  outline: none;
}

.feedbackComment textarea::placeholder {
  color: #C2CCD9;
}

.feedbackComment .buttons {
  width: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  ms-flex-justify-content: flex-end;
  justify-content: flex-end;
}

.feedbackComment .buttons button {
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  padding: 10px 14px;
  transition: all 75ms;
}

.feedbackComment .buttons button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 151, 255, 0.3);
}

.feedbackComment .back {
  display: inline-block;
  background: #E5E9F1;
}

.feedbackComment .back:hover {
  color: var(--hover-secondary-color, #0AAFFF) !important;
  background: var(--hover-secondary-background, #E7F7FF) !important;
}

.feedbackComment .submit {
  display: inline-block;
  color: #FFFFFF;
  background: #0AAFFF;
  margin-left: 10px;
}

.feedbackComment .submit:hover {
  color: var(--hover-primary-color, #FFFFFF) !important;
  background: var(--hover-primary-color, #089AE0) !important;
}

.feedbackCompleted {
  padding-top: 50px;
  text-align: center;
  height: 116px;
}

.feedbackCompleted .icon {
  margin-bottom: 10px;
}

.feedbackCompleted .message {
  font-size: 14px;
}

.selections {
  display: -moz-flex;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  ms-flex-justify-content: center;
  justify-content: center;
}

.selection {
  padding: 5px 15px;
  text-align: center;
  position: relative;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.selection:hover .icon {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  mix-blend-mode: normal;
}

.selection:hover .label {
  opacity: 1;
  bottom: -12px;
}

.icon {
  mix-blend-mode: luminosity;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 75ms ease-in-out !important;
  -o-transition: all 75ms ease-in-out !important;
  transition: all 75ms ease-in-out !important;
  mix-blend-mode: luminosity;
}

.label {
  color: #8592A3;
  font-size: 9px;
  line-height: 10px;
  position: absolute;
  left: 0;
  bottom: -16px;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 75ms ease-in-out !important;
  -o-transition: all 75ms ease-in-out !important;
  transition: all 75ms ease-in-out !important;
}

.emoji-icon {
  vertical-align: top;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../../images/emojis.png);
}

.emoji-icon-md {
  width: 24px;
  height: 25px;
  background-size: 24px;
}

.emoji-icon-md.emoji-icon-importance-1 {
  background-position: 0 0;
}

.emoji-icon-md.emoji-icon-importance-2 {
  background-position: 0 -25px;
}

.emoji-icon-md.emoji-icon-importance-3 {
  background-position: 0 -50px;
}

.emoji-icon-md.emoji-icon-importance-4 {
  background-position: 0 -75px;
}

.emoji-icon-md.emoji-icon-importance-5 {
  background-position: 0 -100px;
}

.emoji-icon-md.emoji-icon-satisfaction-1 {
  background-position: 0 -126px;
}

.emoji-icon-md.emoji-icon-satisfaction-2 {
  background-position: 0 -151px;
}

.emoji-icon-md.emoji-icon-satisfaction-3 {
  background-position: 0 -176px;
}

.emoji-icon-md.emoji-icon-satisfaction-4 {
  background-position: 0 -201px;
}

.emoji-icon-md.emoji-icon-satisfaction-5 {
  background-position: 0 -226px;
}

.emoji-icon-lg {
  width: 38px;
  height: 40px;
  background-size: 38px;
}

.emoji-icon-lg.emoji-icon-importance-1 {
  background-position: 0 0;
}

.emoji-icon-lg.emoji-icon-importance-2 {
  background-position: 0 -40px;
}

.emoji-icon-lg.emoji-icon-importance-3 {
  background-position: 0 -80px;
}

.emoji-icon-lg.emoji-icon-importance-4 {
  background-position: 0 -119px;
}

.emoji-icon-lg.emoji-icon-importance-5 {
  background-position: 0 -159px;
}

.emoji-icon-lg.emoji-icon-satisfaction-1 {
  background-position: 0 -199px;
}

.emoji-icon-lg.emoji-icon-satisfaction-2 {
  background-position: 0 -239px;
}

.emoji-icon-lg.emoji-icon-satisfaction-3 {
  background-position: 0 -279px;
}

.emoji-icon-lg.emoji-icon-satisfaction-4 {
  background-position: 0 -318px;
}

.emoji-icon-lg.emoji-icon-satisfaction-5 {
  background-position: 0 -358px;
}

</style>
