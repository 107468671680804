<template>
  <div class="px-16 py-20">
    <div class="container-3 pr-12 pl-12 mx-auto">
      <div class="text-center pb-12">
        <h1 class="text-2xl pb-1">
          Your trial has expired!
        </h1>

        <p>Add your credit card details to start Exakt {{ product.name }} ({{ price | formatMoneyValue }} per month) subscription.</p>
      </div>

      <ProductTile :show-subscribe-button="false" />

      <CreditCardForm
        class="mt-6"
        action-button-text="Subscribe"
        :show-cancel-button="false"
        @close="isCreditCardModalOpen = false"
        @cardSuccessfullyUpdated="updateProduct"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import formatMoneyValue from '~utils/format-money-value'

import CreditCardForm from './credit-card-form.vue'
import ProductTile from './product-tile.vue'

import { Product } from '~models/product'
import { BillingStatus } from '~models/billing-status'
import { BillingInterval } from '~models/billing-interval'

export default Vue.extend({
  components: {
    ProductTile,
    CreditCardForm,
  },

  filters: {
    formatMoneyValue,
  },

  data() {
    return {
      BillingStatus,
    }
  },

  computed: {
    billingStatus(): BillingStatus | undefined {
      return this.$store.getters['billing/billingStatus']
    },
    product(): Product {
      if (this.billingStatus === BillingStatus.PAYING) {
        return this.$store.getters['billing/currentProduct']
      }

      return this.$store.state.billing?.billing?.allProducts?.filter((product: Product) => product.interval === BillingInterval.MONTH)?.[0]
    },
    price(): number | undefined {
      return this.product?.usdCentsPrice
    },
  },

  async created() {
    await this.$store.dispatch('billing/fetchBilling')

    if (this.billingStatus !== BillingStatus.PAYING) {
      this.$router.push('billing')
    }
  },

  methods: {
    async updateProduct() {
      try {
        await this.$store.dispatch('billing/updateProduct', this.product)
        await this.$store.dispatch('billing/fetchInvoices')
        this.$router.push('/settings/billing')
      } catch (err) {
      }
    },
  },
})
</script>
