<template>
  <div>
    <LogoHeader v-if="$route.meta.logoHeader" />
    <AppNavigation v-if="!$route.meta.hideAppNavigation" />
    <RouterView />
  </div>
</template>

<script>
import Vue from 'vue'
import AppNavigation from '~components/layout/app-navigation.vue'
import LogoHeader from '~components/layout/logo-header.vue'
import loadScript from '~utils/load-script'

export default Vue.extend({
  components: {
    AppNavigation,
    LogoHeader,
  },

  async created() {
    if (this.$store.getters['auth/loggedIn']) {
      if (this.$store.state.surveys.surveys === undefined) {
        await this.$store.dispatch('surveys/fetchAll')
        await this.$store.dispatch('widget/fetch')
        this.loadWidget()
      }

      this.loadIntercom()
    }
  },

  methods: {
    async loadWidget() {
      await loadScript('https://embed.exakt.io/dist/embed.js')
      window.exakt('configure', '24f35fea-b38c-4433-b22f-ed8010a34d91', this.$store.state.auth.user.email)
      // window.exakt('testMode', true)
      // window.exakt('debugMode', true)
    },

    async loadIntercom() {
      window.intercomSettings = {
        app_id: 'm5a3xrps',
        user_id: this.$store.state.auth.user.id,
        name: this.$store.state.auth.user.fullName,
        email: this.$store.state.auth.user.email,
      }

      if (this.$store.state.auth.user.account) {
        Object.assign(window.intercomSettings, {
          company: {
            company_id: this.$store.state.auth.user.account.id,
            name: this.$store.state.auth.user.account.company || this.$store.state.auth.user.account.id,
          },
        })
      }

      console.log('loading intercom', window.intercomSettings)

      if (typeof window.Intercom === 'function') {
        window.Intercom('reattach_activator')
        window.Intercom('update', w.intercomSettings)
      } else {
        const temporaryIntercom = (...args) => temporaryIntercom.c(...args)
        temporaryIntercom.q = []
        temporaryIntercom.c = (args) => temporaryIntercom.q.push(args)
        window.Intercom = temporaryIntercom

        await loadScript('https://widget.intercom.io/widget/m5a3xrps')
      }
    },
  },
})
</script>
