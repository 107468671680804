<template>
  <div class="w-full flex justify-center pt-5">
    <svg width="87" height="36" viewBox="0 0 87 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 11.02v13.96a4.04 4.04 0 002.02 3.498l12.091 6.98a4.038 4.038 0 004.04 0l12.088-6.98a4.04 4.04 0 002.02-3.497V11.019a4.04 4.04 0 00-2.02-3.497L18.15.542a4.04 4.04 0 00-4.039 0L2.02 7.521A4.04 4.04 0 000 11.019z" fill="#0097FF"/><path d="M6.637 25.228l12.02-.017a2.85 2.85 0 002.457-1.425l5.995-10.42a1.805 1.805 0 00-1.57-2.705l-12.019.016a2.852 2.852 0 00-2.456 1.426L5.069 22.514a1.806 1.806 0 001.568 2.714z" fill="#fff"/><path d="M16.13 22.258a4.258 4.258 0 100-8.516 4.258 4.258 0 000 8.516zM40.78 11.647h8.496v1.995h-5.969v3.726h4.78v1.9h-4.78v3.973H49.6v1.996h-8.82v-13.59zM54.221 20.139L50.99 15.29h2.66l2.073 3.213 2.053-3.213h2.414l-3.213 4.681 3.564 5.265h-2.69l-2.318-3.592-2.376 3.593h-2.395l3.46-5.1zM61.027 22.48c0-2.318 2.243-3.154 5.094-3.154h1.236v-.514c0-1.33-.456-1.995-1.806-1.995-1.188 0-1.787.57-1.9 1.558h-2.225c.19-2.319 2.034-3.288 4.258-3.288s3.973.912 3.973 3.63v6.52h-2.262v-1.216c-.646.855-1.52 1.406-3.041 1.406-1.825 0-3.327-.874-3.327-2.946zm6.33-.712v-.988h-1.188c-1.749 0-2.87.399-2.87 1.615 0 .837.456 1.388 1.596 1.388 1.417-.01 2.462-.75 2.462-2.024v.009zM71.781 10.696h2.303v8.967l3.564-4.372h2.509l-3.859 4.562 4.182 5.38h-2.661l-3.735-4.881v4.885H71.78V10.696zM82.35 22.462v-5.436h-1.33v-1.73h1.33V13.11h2.3v2.186h2.186v1.73H84.65v5.227c0 .875.418 1.274 1.14 1.274.383.013.765-.052 1.122-.19v1.824a4.423 4.423 0 01-1.559.238c-1.977.01-3.003-1.055-3.003-2.937z" fill="#000"/></svg>
  </div>
</template>

<script>
export default {

}
</script>
