<template>
  <div class="px-16 pb-20 pt-10">
    <div class="container-2">
      <Navigation />

      <Alert
        v-if="display.justSubscribedNotice"
        variant="info"
        class="mb-12"
      >
        Thank you for subscribing to {{ product.name }}

        <div class="cursor-pointer flex absolute right-0 mr-5 p-1 opacity-50 hover:opacity-100 transition duration-75" @click.prevent="setDisplay('justSubscribedNotice', false)">
          <svg width="12" height="12" fill="#0AAFFF" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.85 10.44a.5.5 0 010 .71l-.7.7a.5.5 0 01-.71 0L6 7.41l-4.44 4.44a.5.5 0 01-.71 0l-.7-.7a.5.5 0 010-.71L4.59 6 .15 1.56a.5.5 0 010-.71l.7-.7a.5.5 0 01.71 0L6 4.59 10.44.15a.5.5 0 01.71 0l.7.7a.5.5 0 010 .71L7.41 6l4.44 4.44z"/></svg>
        </div>
      </Alert>

      <Alert
        v-if="billingStatus === BillingStatus.TRIALING"
        variant="info"
        class="mb-12"
      >
        {{ trialAlertText }}
      </Alert>

      <Alert
        v-if="error"
        variant="error"
        class="mb-12"
      >
        There was a problem with the payment. Please try again.

        <div class="cursor-pointer flex absolute right-0 mr-5 opacity-50 hover:opacity-100 transition duration-75" @click.prevent="clearError">
          <svg width="12" height="12" fill="#eb5757" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.85 10.44a.5.5 0 010 .71l-.7.7a.5.5 0 01-.71 0L6 7.41l-4.44 4.44a.5.5 0 01-.71 0l-.7-.7a.5.5 0 010-.71L4.59 6 .15 1.56a.5.5 0 010-.71l.7-.7a.5.5 0 01.71 0L6 4.59 10.44.15a.5.5 0 01.71 0l.7.7a.5.5 0 010 .71L7.41 6l4.44 4.44z"/></svg>
        </div>
      </Alert>

      <div class="max-w-5xl">
        <div class="mb-8">
          <ProductTile />
        </div>

        <div v-if="!card" class="mb-8">
          <ActiveCreditCard />
        </div>

        <div class="mb-8">
          <NextPayment />
        </div>

        <div>
          <Invoices />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import Navigation from './navigation.vue'
import Invoices from './billing/invoices.vue'
import ActiveCreditCard from './billing/active-credit-card.vue'
import NextPayment from './billing/next-payment.vue'
import ProductTile from './billing/product-tile.vue'
import Alert from '~components/alert/alert.vue'

import { Product } from '~models/product'
import { Card } from '~models/card'
import { BillingStatus } from '~models/billing-status'
import { BillingInterval } from '~models/billing-interval'
import { UserRole } from '~models/user'

export default Vue.extend({
  components: {
    Navigation,
    ActiveCreditCard,
    NextPayment,
    ProductTile,
    Invoices,
    Alert,
  },

  data() {
    return {
      UserRole,
      BillingStatus,
    }
  },

  computed: {
    card(): Card | undefined {
      return this.$store.getters['billing/card']
    },
    error(): boolean {
      return this.$store.getters['billing/error']
    },
    display(): Object {
      return this.$store.getters['billing/display']
    },
    billingStatus(): BillingStatus | undefined {
      return this.$store.getters['billing/billingStatus']
    },
    trialDaysLeft(): number {
      return this.$store.state.billing?.billing?.trialDaysLeft || 0
    },
    trialAlertText(): string {
      if (this.trialDaysLeft > 0) {
        return `Your trials ends in ${this.trialDaysLeft} days`
      }

      return 'Your trial has expired. Please subscribe to continue!'
    },
    product(): Product {
      if (this.billingStatus === BillingStatus.PAYING) {
        return this.$store.getters['billing/currentProduct']
      }

      return this.$store.state.billing?.billing?.allProducts?.filter((product: Product) => product.interval === BillingInterval.MONTH)?.[0]
    },
    userRole(): UserRole {
      return this.$store.state.auth.user.role
    },
  },

  methods: {
    clearError() {
      this.$store.dispatch('billing/setError', { error: false })
    },
    setDisplay(key: string, value: boolean) {
      this.$store.dispatch('billing/setDisplay', { key, value })
    },
  },
})
</script>
