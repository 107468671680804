// https://stripe.com/docs/api/cards/object#card_object-brand
// eslint-disable-next-line import/prefer-default-export
export enum CardBrand {
  AMEX = 'American Express',
  DINERS = 'Diners Club',
  DISCOVER = 'Discover',
  JCB = 'JCB',
  MASTER_CARD = 'MasterCard',
  UNION_PAY = 'UnionPay',
  VISA = 'Visa',
  UNKNOWN = 'Unknown',
}

export interface Card {
  last4: string;
  brand: CardBrand;
  expiresAt: string;
  expired: boolean;
}
