<template>
  <div v-if="!survey" class="flex justify-center items-center h-screen">
    <Spinner />
  </div>
  <div v-else class="px-16 pt-10 pb-20">
    <div class="container">
      <div v-if="showChangesSavedNotice" class="fixed bottom-0 right-0 mr-12 mb-12 py-3 w-75 text-center rounded-6px text-sm bg-green text-white shadow">
        Changes saved successfully
      </div>

      <p class="mb-6">
        <RouterLink to="/surveys" class="transition duration-75 ease-in-out text-sm text-navy-300 cursor-pointer hover:text-blue">
          Back to Surveys
        </RouterLink>
      </p>
      <div class="mb-12 flex items-center justify-between">
        <div class="flex items-center max-w-xl">
          <h1 v-if="survey.name === ''" class="text-2xl font-medium mr-4 text-navy-300">
            Untitled survey
          </h1>
          <h1 v-else class="text-2xl font-medium mr-4">
            {{ survey.name }}
          </h1>
          <Badge :variant="badgeVariantForStatus(survey.status)">
            {{ survey.status | capitalize }}
          </Badge>
        </div>

        <div class="ml-3 flex">
          <Button
            v-if="survey.status === SurveyStatus.Live"
            variant="outline"
            class="flex items-center"
            @click.prevent="updateSurveyStatus(SurveyStatus.Paused)"
          >
            <div class="flex-shrink-0 mr-2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'outline-none'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.334 14.375v-8.75A.633.633 0 007.709 5h-1.25a.633.633 0 00-.625.625v8.75c.004.343.282.62.625.625h1.25a.633.633 0 00.625-.625zM12.292 5h1.25c.344.004.62.282.625.625v8.75a.633.633 0 01-.625.625h-1.25a.633.633 0 01-.625-.625v-8.75A.633.633 0 0112.292 5z" fill="#8592A3"/></svg>
            </div>
            Pause
          </Button>

          <Button
            v-if="id !== 'new' && survey.status !== SurveyStatus.Live"
            variant="outline"
            class="flex items-center"
            @click.prevent="updateSurveyStatus(SurveyStatus.Live)"
          >
            <div class="flex-shrink-0 mr-2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'outline-none'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 9.883v.234a.633.633 0 01-.308.541L7.233 15c-.45.267-.683.267-.883.15l-.208-.117a.633.633 0 01-.309-.525V5.492c.001-.222.118-.428.309-.542l.208-.117c.2-.116.433-.116 1.017.225l7.325 4.284c.19.114.307.32.308.541z" fill="#8592A3"/></svg>
            </div>
            Set live
          </Button>

          <Button
            class="btn-primary px-4 py-3 ml-3"
            @click.prevent="submit"
          >
            Save changes
          </Button>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="w-4/12">
          <form action="">
            <div class="mb-6">
              <FormInput
                v-model="survey.name"
                type="text"
                label="Name"
                :error="savingAttempted && !survey.name"
              />
              <p class="text-xs text-navy-300 leading-none pt-2">
                Only you and your team will see this
              </p>
            </div>

            <div class="mb-8">
              <p class="text-sm font-medium mb-4">
                Questions
              </p>
              <div class="mb-6">
                <FormInput
                  v-model="survey.importanceQuestion"
                  type="text"
                  label="Importance question"
                  placeholder="e.g. How important is dashboard to you?"
                  :error="savingAttempted && (!hasQuestions && !survey.importanceQuestion)"
                />
              </div>

              <div class="mb-6">
                <FormInput
                  v-model="survey.satisfactionQuestion"
                  type="text"
                  label="Satisfaction question"
                  placeholder="e.g. How satisfied are you with dashboard?"
                  :error="savingAttempted && (!hasQuestions && !survey.satisfactionQuestion)"
                />
              </div>

              <div class="mb-6">
                <FormInput
                  v-model="survey.feedbackQuestion"
                  type="text"
                  label="Open question"
                  placeholder="Anything else you'd like to share?"
                  :error="savingAttempted && (!hasQuestions && !survey.feedbackQuestion)"
                />
              </div>
            </div>

            <div class="mb-8">
              <p class="text-sm font-medium mb-4">
                Exakt link
              </p>

              <Toggle
                id="exakt-link"
                v-model="survey.exaktLink"
                label="Display a link to the Exakt website"
              />
            </div>

            <div class="mb-8">
              <p class="text-sm font-medium mb-4">
                What page to show this survey on?
              </p>
              <FormInput
                v-model="survey.pageUrl"
                type="text"
                label="URL address"
                :error="savingAttempted && !survey.pageUrl"
              />
              <p class="text-xs text-navy-300 leading-none pt-2">
                Use an asterisk (*) as a wildcard
              </p>
            </div>

            <div class="mb-8">
              <p class="text-sm font-medium mb-4">
                Number of visits
              </p>
              <div class="mb-3">
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    v-model="survey.numberOfVisitsToShow"
                    type="radio"
                    class="form-radio text-blue cursor-pointer w-3.5 h-3.5"
                    name="radio2"
                    :value="0"
                  >
                  <span class="ml-2 text-sm">Whenever the customer visits the page</span>
                </label>
              </div>
              <div class="mb-3">
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    class="form-radio text-blue cursor-pointer border-blue-border-200 w-3.5 h-3.5"
                    name="radio2"
                    :checked="survey.numberOfVisitsToShow > 0"
                    @change="survey.numberOfVisitsToShow = numberOfVisitsToShow || 5"
                  >
                  <span class="ml-2 text-sm">After certain number of visits</span>
                </label>
              </div>
              <div v-if="survey.numberOfVisitsToShow !== 0" class="flex items-center pl-6">
                <div class="w-24">
                  <FormInput
                    v-model="survey.numberOfVisitsToShow"
                    type="number"
                  />
                </div>
                <span class="ml-2 pt-1 leading-none text-sm">visits</span>
              </div>
            </div>

            <div class="mb-8">
              <p class="text-sm font-medium mb-4">
                When to show this survey?
              </p>
              <div class="mb-3">
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    v-model="survey.secondsToShow"
                    type="radio"
                    class="form-radio text-blue cursor-pointer w-3.5 h-3.5"
                    name="radio3"
                    :value="0"
                  >
                  <span class="ml-2 text-sm">When the customer lands on the page</span>
                </label>
              </div>
              <div class="mb-3">
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    class="form-radio text-blue cursor-pointer border-blue-border-200 w-3.5 h-3.5"
                    name="radio3"
                    :checked="survey.secondsToShow > 0"
                    @change="survey.secondsToShow = originalSecondsToShow || 5"
                  >
                  <span class="ml-2 text-sm">After a period of time on page</span>
                </label>
              </div>
              <div v-if="survey.secondsToShow !== 0" class="flex items-center pl-6">
                <div class="w-24">
                  <FormInput
                    v-model="survey.secondsToShow"
                    type="number"
                  />
                </div>
                <span class="ml-2 pt-1 leading-none text-sm">seconds</span>
              </div>
            </div>

            <div class="mb-8">
              <p class="text-sm font-medium mb-4">
                How often to show this survey to the same person?
              </p>

              <label class="leading-none text-sm pb-2 block">Every</label>
              <FrequencyDropdown v-model="survey.frequencyInDays" :options="FREQUENCY_OPTIONS" />
            </div>

            <div class="mb-8">
              <Button
                class="btn-primary px-4 py-3"
                @click.prevent="submit"
              >
                Save changes
              </Button>
            </div>

            <div class="mb-8">
              <div class="py-4">
                <p class="text-sm">
                  Would you like to remove this survey?
                </p>
              </div>

              <Button
                variant="outline"
                class="flex items-center mr-3 px-4 py-3"
                @click.prevent="isShowingDeleteModal = true"
              >
                <div class="flex-shrink-0 mr-2">
                  <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.875 8.7a.417.417 0 010 .592l-.583.583a.417.417 0 01-.592 0L5 6.175l-3.7 3.7a.417.417 0 01-.591 0l-.584-.583a.417.417 0 010-.592l3.7-3.7-3.7-3.7a.417.417 0 010-.592L.71.125a.417.417 0 01.591 0l3.7 3.7 3.7-3.7a.417.417 0 01.592 0l.583.583a.417.417 0 010 .592L6.175 5l3.7 3.7z" fill="#8592A3"/></svg>
                </div>
                Remove
              </Button>
            </div>
          </form>
        </div>

        <WidgetPreview
          v-if="widget"
          :survey="survey"
          :widget="widget"
        />

        <Modal
          v-if="isShowingDeleteModal"
          title="Delete Survey"
          cancel-button-text="Cancel"
          :show-cancel-button="true"
          @close="isShowingDeleteModal = false"
        >
          <template #default>
            <p class="text-sm leading-snug">
              Are you sure you'd like to remove this survey?
            </p>
          </template>

          <template #action>
            <Button
              variant="primary"
              @click.prevent="remove"
            >
              Confirm change
            </Button>
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import Spinner from '~components/spinner/spinner.vue'
import Button from '~components/button/button.vue'
import FormInput from '~components/form/input.vue'
import WidgetPreview from '~components/widget-preview/widget-preview.vue'
import Modal from '~components/modal/modal.vue'
import Toggle from '~components/toggle/toggle.vue'

import FrequencyDropdown, { Option } from './frequency-dropdown.vue'
import Badge, { BadgeVariant } from '~components/badge/badge.vue'

import { SurveyParams, Survey, SurveyStatus } from '~models/survey'
import { Widget } from '~models/widget'

export const DEFAULT_SURVEY: SurveyParams = {
  status: SurveyStatus.Draft,
  frequencyInDays: 30,
  name: '',
  numberOfVisitsToShow: 3,
  pageUrl: '',
  importanceQuestion: 'How important is feature to you?',
  satisfactionQuestion: 'How satisfied are you with feature?',
  feedbackQuestion: 'Anything else you\'d like to share?',
  secondsToShow: 5,
  exaktLink: true,
}

const FREQUENCY_OPTIONS: Option[] = [
  { value: 30, formatted: '1 month' },
  { value: 60, formatted: '2 months' },
  { value: 90, formatted: '3 months' },
  { value: 0, formatted: 'Just once' },
]

export default Vue.extend({
  components: {
    Spinner,
    Button,
    Badge,
    FormInput,
    FrequencyDropdown,
    WidgetPreview,
    Modal,
    Toggle,
  },

  filters: {
    capitalize(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data(): {
    SurveyStatus: typeof SurveyStatus
    FREQUENCY_OPTIONS: typeof FREQUENCY_OPTIONS
    survey?: SurveyParams
    originalSecondsToShow?: number
    showChangesSavedNotice: boolean
    savingAttempted: boolean
    isShowingDeleteModal: boolean
  } {
    return {
      SurveyStatus,
      FREQUENCY_OPTIONS,
      survey: undefined,
      originalSecondsToShow: undefined,
      showChangesSavedNotice: false,
      savingAttempted: false,
      isShowingDeleteModal: false,
    }
  },

  computed: {
    hasQuestions(): boolean {
      return [
        this.survey?.importanceQuestion,
        this.survey?.satisfactionQuestion,
        this.survey?.feedbackQuestion,
      ].some((el) => !!el)
    },
    validationError(): boolean {
      if (this.survey === undefined) {
        return false
      }

      if (!this.hasQuestions) {
        return true
      }

      return [
        this.survey.name,
        this.survey.pageUrl,
      ].some((el) => !el)
    },
    widget(): Widget | undefined {
      return this.$store.state.widget?.widget
    },
  },

  watch: {
    id: {
      immediate: true,
      handler(id) {
        if (id === 'new') {
          this.survey = { ...DEFAULT_SURVEY }
        } else {
          this.survey = this.$store.state.surveys.surveys.find((survey: Survey) => survey.id === id)
        }
      },
    },
    'survey.secondsToShow': {
      handler(newValue, oldValue) {
        if (newValue === 0) {
          this.originalSecondsToShow = oldValue
        }
      },
    },
  },

  created() {
    this.$store.dispatch('widget/fetch')
  },

  methods: {
    badgeVariantForStatus(status: SurveyStatus): BadgeVariant {
      return {
        [SurveyStatus.Draft]: BadgeVariant.Muted,
        [SurveyStatus.Live]: BadgeVariant.Success,
        [SurveyStatus.Paused]: BadgeVariant.Default,
      }[status]
    },
    async submit(): Promise<void> {
      if (this.validationError) {
        this.savingAttempted = true
        return
      }

      if (this.id === 'new') {
        const data = await this.$store.dispatch('surveys/create', this.survey)
        this.$router.push({ name: 'survey', params: { id: data.id } })
      } else {
        const data = await this.$store.dispatch('surveys/update', this.survey)
        this.survey = data
        this.showChangesSavedNotice = true
        setTimeout(() => {
          this.showChangesSavedNotice = false
        }, 3000)
        window.scrollTo(0, 0)
      }
    },
    async remove(): Promise<void> {
      if (this.id !== 'new') {
        await this.$store.dispatch('surveys/delete', this.id)
      }
      await Vue.nextTick()
      this.isShowingDeleteModal = false
      this.$router.push({ name: 'surveys' })
    },
    async updateSurveyStatus(status: SurveyStatus): Promise<void> {
      const data = await this.$store.dispatch('surveys/update', { id: this.id, status })
      this.survey = data
    },
  },
})
</script>
