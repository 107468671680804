/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser'
import { Module } from 'vuex'
import axios from '~plugins/axios'

import { Billing } from '~models/billing'
import { BillingInterval } from '~models/billing-interval'
import { Invoice } from '~models/invoice'

import { RootState, ModuleContext } from '~plugins/store'

export interface State {
  billing?: Billing;
  invoices?: Invoice[];
  loading?: boolean;
  error?: boolean;
  display: Record<string, unknown>;
}

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    billing: undefined,
    invoices: undefined,
    loading: false,
    error: false,
    display: {
      justSubscribedNotice: false,
    },
  },
  getters: {
    card: (state) => state.billing?.card,
    currentProduct: (state) => state.billing?.product,
    yearlyProduct: (state) => state.billing?.allProducts.find((product) => product.interval === BillingInterval.YEAR),
    monthlyProduct: (state) => state.billing?.allProducts.find((product) => product.interval === BillingInterval.MONTH),
    billingStatus: (state) => state.billing?.billingStatus,
    currentPeriodEnd: (state) => state.billing?.currentPeriodEnd,
    loading: (state) => state.loading,
    error: (state) => state.error,
    display: (state) => state.display,
    hasLoaded: (state) => state.billing !== undefined,
    needsCreditCardUpdate: (state) => !state.billing?.card || state.billing?.card?.expired,
  },
  actions: {
    async fetchBilling({ commit }: ModuleContext<State>): Promise<void> {
      commit('SET_LOADING', { loading: true })

      try {
        const { data } = await axios.get('billing')
        commit('SET_BILLING', { data })
      } catch (err) {
        commit('SET_ERROR', { error: true })
        Sentry.captureException(err)
      }
    },
    async cancelBilling({ commit }) {
      commit('SET_LOADING', { loading: true })

      try {
        const { data } = await axios.put('billing/cancel')
        commit('SET_BILLING', { data })
      } catch (err) {
        commit('SET_ERROR', { error: true })
      }
    },
    async updateProduct({ commit }, { id }) {
      commit('SET_LOADING', { loading: true })

      try {
        const { data } = await axios.post('billing/update_product', {
          productId: id,
        })

        commit('SET_BILLING', { data })
        commit('SET_DISPLAY', { key: 'justSubscribedNotice', value: true })
      } catch (err) {
        commit('SET_ERROR', { error: true })
      }
    },
    async fetchInvoices({ commit }) {
      commit('SET_LOADING', { loading: true })

      try {
        const { data } = await axios.get('billing/invoices')
        commit('SET_INVOICES', { data })
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    async updateCard({ commit }, { stripeToken }) {
      try {
        commit('SET_LOADING', { loading: true })
        const { data } = await axios.post('billing/update_card', { stripeToken })
        commit('SET_BILLING', { data })
      } catch (err) {
        commit('SET_ERROR', { error: true })
      }
    },
    setError({ commit }, { error }) {
      commit('SET_ERROR', { error })
    },
    setDisplay({ commit }, { key, value }) {
      commit('SET_DISPLAY', { key, value })
    },
  },
  mutations: {
    SET_ERROR(state, { error }) {
      state.error = error
    },
    SET_LOADING(state, { loading }) {
      state.loading = loading
    },
    SET_DISPLAY(state, { key, value }) {
      state.display = {
        ...state.display,
        [key]: value,
      }
    },
    SET_BILLING(state, { data }) {
      state.billing = data
      state.loading = false
    },
    SET_INVOICES(state, { data }) {
      state.invoices = data
      state.loading = false
    },
  },
}

export default store
