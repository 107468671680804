<template>
  <div v-if="hasLoaded" class="mb-12">
    <p class="pb-5">
      Invoices
    </p>
    <p class="text-navy-300 text-sm">
      View invoices for all payments made on your Exakt account.
    </p>

    <div class="mt-8">
      <div class="flex items-center text-sm leading-none border-b border-blue-border-100 pb-4">
        <div class="w-75 pr-4">
          Date
        </div>
        <div class="flex-1 pr-4">
          Description
        </div>
        <div class="w-30 text-center pr-4">
          &nbsp;
        </div>
        <div class="w-25 text-right pr-4">
          Amount
        </div>
      </div>

      <div
        v-for="invoice in invoices"
        :key="invoice.id"
        class="flex items-center text-sm leading-none border-b border-blue-border-100 py-5"
      >
        <div class="w-75 pr-4">
          {{ parseDate(invoice.date) }}
        </div>
        <div class="flex-1 pr-4">
          {{ invoice.description }}
        </div>
        <div class="w-30 text-center pr-4">
          <a :href="invoice.invoicePdf" class="text-blue">Download</a>
        </div>
        <div class="w-25 text-right pr-4">
          {{ invoice.amountPaid | formatMoneyValue }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { parseISO, format } from 'date-fns'
import formatMoneyValue from '~utils/format-money-value'

import { Invoice } from '~models/invoice'

export default Vue.extend({
  filters: {
    formatMoneyValue,
  },

  computed: {
    invoices(): Invoice[] {
      return this.$store.state.billing?.invoices
    },
    hasLoaded(): boolean {
      return !!this.invoices?.length
    },
  },

  async created() {
    await this.$store.dispatch('billing/fetchInvoices')
  },

  methods: {
    parseDate(date: string) {
      return format(parseISO(date), 'dd LLLL yyyy')
    },
  },
})
</script>
