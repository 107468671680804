<template>
  <div class="px-16 py-10">
    <div class="container">
      <div class="flex">
        <ScoreSummary />
        <div class="w-3/5 m-2">
          <h3 class="text-xl font-medium mb-6">
            {{ survey.name }}
          </h3>


          <div class="flex mb-6">

            <div class="w-1/5">
              <p class="text-xs text-navy-300">Sent</p>
              <p class="text-xs">{{ survey.replyCount }}</p>
            </div>

            <div class="w-1/5">
              <p class="text-xs text-navy-300">Replies</p>
              <p class="text-xs">{{ survey.replyCount }}</p>
            </div>

            <div class="w-1/5">
              <p class="text-xs text-navy-300">Reply rate</p>
              <p class="text-xs">{{ survey.responseRate | inPercent }}</p>
            </div>

            <div class="w-1/5">
              <p class="text-xs text-navy-300">Importance</p>
              <p class="text-xs">{{ survey.avgImportanceScore }}</p>
            </div>

            <div class="w-1/5">
              <p class="text-xs text-navy-300">Satisfaction</p>
              <p class="text-xs">{{ survey.avgSatisfactionScore }}</p>
            </div>

          </div>


          <Toggle
            id="comments-only"
            v-model="filterOnCommentsOnly"
            label="With comments only"
          />

          <div v-if="replies.length > 0">
            <ReplyRow
              v-for="reply in replies"
              :key="reply.id"
              :reply="reply"
            />
          </div>

          <div v-else>
            <p class="text-sm text-navy-300">
              No replies, yet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { parseISO, formatDistanceToNow } from 'date-fns'

import ReplyRow from './reply-row.vue'
import ScoreSummary from './score-summary.vue'
import Toggle from '~components/toggle/toggle.vue'

import { Reply } from '~models/reply'
import { Survey, SurveyWithStats } from '~vue/models/survey'

export default Vue.extend({
  components: {
    ReplyRow,
    ScoreSummary,
    Toggle,
  },

  props: {
    surveyId: {
      type: String,
      required: true,
    },
  },

  data(): {
    currentPage: number;
    filterOnCommentsOnly: boolean;
  } {
    return {
      currentPage: 1,
      filterOnCommentsOnly: false,
    }
  },

  filters: {
    distanceToNow(timestamp: string | null): string {
      if (timestamp === null) {
        return '–'
      }
      const parsed = parseISO(timestamp)
      return `${formatDistanceToNow(parsed)} ago`
    },
    capitalize(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    inPercent(value: number | null): string {
      if (value === null) {
        return '–'
      }

      return `${value}%`
    },
  },

  computed: {
    survey(): Survey {
      return this.$store.state.surveys.surveys.find((survey: Survey) => survey.id === this.surveyId)
    },
    replies(): Reply[] {
      return this.$store.getters['replies/repliesForSurvey'](this.surveyId).filter((reply: Reply) => {
        if (!this.filterOnCommentsOnly) {
          return true
        }

        return reply.comment
      })
    },
  },

  watch: {
    surveyId: {
      immediate: true,
      handler(): void {
        this.fetchReplies(this.currentPage)
      },
    },
  },

  methods: {
    async fetchReplies(page: number): Promise<void> {
      await this.$store.dispatch('replies/fetch', { surveyId: this.surveyId, page })
    },
  },
})
</script>
