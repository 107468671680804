<template>
  <span class="text-sm rounded-md inline-flex items-center px-2 py-1" :class="variant">
    <slot />
  </span>
</template>

<script lang="ts">
import Vue from 'vue'

export enum BadgeVariant {
  Default = 'default',
  Success = 'success',
  Muted = 'muted',
}

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (v: BadgeVariant): boolean => Object.values(BadgeVariant).includes(v),
    },
  },
})
</script>

<style lang="scss" scoped>
.default {
  @apply bg-gray-100;
  @apply text-navy-800;
}

.success {
  @apply bg-green-100;
  @apply text-green;
}

.muted {
  @apply border;
  @apply border-gray-100;
  @apply text-navy-300;
}
</style>
