/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser'
import { Module } from 'vuex'
import axios from '~plugins/axios'
import router from '~plugins/router'

import { User } from '~models/user'
import { Account } from '~models/account'

import { RootState, ModuleContext } from '~plugins/store'

export interface State {
  status: { loggedIn: boolean, loggingIn: boolean };
  user?: User;
  authToken?: string;
}

const user: User = JSON.parse(localStorage.getItem('user') as string)
const initialAuthToken = localStorage.getItem('authToken')
const initialState = (initialAuthToken && user)
  ? { status: { loggedIn: true, loggingIn: false }, user, authToken: initialAuthToken }
  : { status: { loggedIn: false, loggingIn: false } }

const store: Module<State, RootState> = {
  namespaced: true,
  state: initialState,
  getters: {
    loggedIn: (state: State): boolean => state.status.loggedIn,
    account: (state: State): Account | undefined => state.user?.account,
  },
  actions: {
    signupLogin({ commit }: ModuleContext<State>, { data, headers }): void {
      commit('SET_LOGGING_IN')
      const authToken = headers.authorization
      commit('LOGIN_SUCCESS', { data, authToken })
    },
    async refreshUser({ commit }: ModuleContext<State>): Promise<void> {
      const { data } = await axios.get('me')
      commit('SET_USER_DATA', data)
    },
    async login({ commit }: ModuleContext<State>, { email, password }): Promise<void> {
      commit('SET_LOGGING_IN')

      try {
        const { data, headers } = await axios.post('users/sign_in', { user: { email, password } })
        const authToken = headers.authorization
        commit('LOGIN_SUCCESS', { data, authToken })
        router.push({ name: 'results' })
      } catch (err) {
        commit('LOGIN_FAILURE')
        throw err
      }
    },
    async logout({ commit }: ModuleContext<State>): Promise<void> {
      await axios.delete('users/sign_out')
      commit('LOGOUT')
    },
    async updateUser({ commit }: ModuleContext<State>, userData): Promise<void> {
      try {
        const { data } = await axios.put('me', userData)
        commit('SET_USER_DATA', data)
      } catch (err) {
        Sentry.captureException(err)
        throw err
      }
    },
    async updatePassword(context, { currentPassword, password, passwordConfirmation }) {
      await axios.put('me/update_password', { currentPassword, password, passwordConfirmation })
    },
    async updateAvatar({ commit }, file) {
      // Update the UI right away
      const reader = new FileReader()
      reader.onloadend = () => {
        commit('UPDATE_AVATAR_URL', reader.result)
      }
      reader.readAsDataURL(file)

      // Upload the new file
      const headers = { 'Content-Type': 'multipart/form-data' }
      const formData = new FormData()
      formData.append('avatar', file)
      const { data } = await axios.put('me', formData, { headers })
      commit('SET_USER_DATA', data)
    },
    async authCheck({ commit, getters }) {
      if (!getters.loggedIn) {
        return
      }

      try {
        await axios.get('auth_check')
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        commit('LOGOUT')
      }
    },
  },
  mutations: {
    SET_LOGGING_IN(state) {
      state.status = { loggedIn: false, loggingIn: true }
    },
    LOGIN_SUCCESS(state, { data, authToken }) {
      state.user = data
      state.authToken = authToken
      state.status = { loggedIn: true, loggingIn: false }

      // save to localStorage as well
      localStorage.setItem('user', JSON.stringify(data))
      localStorage.setItem('authToken', authToken)

      // Set auth header
      axios.defaults.headers.common.authorization = authToken
    },
    SET_USER_DATA(state, userData) {
      state.user = userData
      state.status = { loggedIn: true, loggingIn: false }
      localStorage.setItem('user', JSON.stringify(userData))
    },
    UPDATE_AVATAR_URL(state, avatarUrl) {
      if (!state.user) {
        return
      }
      state.user.avatarUrl = avatarUrl
    },
    LOGIN_FAILURE(state) {
      state.status = { loggedIn: false, loggingIn: false }
      state.user = undefined
    },
    LOGOUT(state) {
      state.status = { loggedIn: false, loggingIn: false }
      state.user = undefined
      state.authToken = undefined
      localStorage.clear()
      window.location.reload()
      delete axios.defaults.headers.common.authorization
    },
  },
}

export default store
