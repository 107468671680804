import { Account } from './account'

export enum UserRole {
  ADMIN = 'admin',
  NORMAL = 'normal',
  VIEWER = 'viewer',
}

export enum UserStatus {
  REGISTERED = 'registered',
  INVITED = 'invited',
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  email?: string;
  account?: Account;
  isBillingUser?: boolean;
  role?: UserRole;
  status: UserStatus
}
