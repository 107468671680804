<template>
  <div class="px-16 py-10">
    <div class="container">

      <div v-if="surveys.length > 0">
        <div class="mb-12 flex items-center justify-between">
          <h1 class="text-3xl font-medium">
            Surveys
          </h1>
          <Button :to="{ name: 'survey', params: { id: 'new' } }" class="flex items-center px-4 py-3">
            <div class="flex-shrink-0 mr-2">
              <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" d="M11.25 5.625v.75a.375.375 0 01-.375.375H6.75v4.125a.375.375 0 01-.375.375h-.75a.375.375 0 01-.375-.375V6.75H1.125a.375.375 0 01-.375-.375v-.75c0-.207.168-.375.375-.375H5.25V1.125c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375V5.25h4.125c.207 0 .375.168.375.375z"/></svg>
            </div>
            New survey
          </Button>
        </div>
        <div class="flex items-center w-full text-xs text-navy-300 leading-none pb-4">
          <div class="w-30pct">
            Survey
          </div>
          <div class="w-13pct">
            Status
          </div>
          <div class="w-9pct">
            Sent
          </div>
          <div class="w-18pct">
            Response rate
          </div>
          <div class="w-15pct">
            Last sent
          </div>
          <div class="w-15pct">
            Created at
          </div>
        </div>

        <div
          v-for="survey in surveys"
          :key="survey.id"
          class="flex items-center w-full text-sm border-t border-blue-border-100 py-4"
        >
          <div class="w-30pct pr-4 truncate">
            <RouterLink :to="{ name: 'survey', params: { id: survey.id } }" class="leading-tight text-sm hover:text-blue cursor-pointer">
              {{ survey.name }}
            </RouterLink>
            <p class="text-xxs text-navy-300 mt-2 leading-tight">
              {{ survey.pageUrl }}
            </p>
          </div>
          <div class="w-13pct">
            <Badge :variant="badgeVariantForStatus(survey.status)">
              {{ survey.status | capitalize }}
            </Badge>
          </div>
          <div class="w-9pct text-xs">
            {{ survey.replyCount }}
          </div>
          <div class="w-18pct text-xs">
            {{ survey.responseRate | inPercent }}
          </div>
          <div class="w-15pct text-xs">
            {{ survey.maxCreatedAt | distanceToNow | capitalize }}
          </div>
          <div class="w-15pct text-xs">
            {{ survey.createdAt | distanceToNow | capitalize }}
          </div>
        </div>
      </div>

      <div v-else>
        <div class="empty-state pt-24">
          <h2 class="text-center text-2xl mb-2 font-medium">Create survey</h2>
          <p class="text-center text-navy-300 mb-8">Create new survey to start collecting customer insights</p>
          <div class="mx-auto flex">
            <Button :to="{ name: 'survey', params: { id: 'new' } }" class="flex items-center px-4 py-3 mx-auto">
              <div class="flex-shrink-0 mr-2">
                <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" d="M11.25 5.625v.75a.375.375 0 01-.375.375H6.75v4.125a.375.375 0 01-.375.375h-.75a.375.375 0 01-.375-.375V6.75H1.125a.375.375 0 01-.375-.375v-.75c0-.207.168-.375.375-.375H5.25V1.125c0-.207.168-.375.375-.375h.75c.207 0 .375.168.375.375V5.25h4.125c.207 0 .375.168.375.375z"/></svg>
              </div>
              New survey
            </Button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { parseISO, formatDistanceToNow } from 'date-fns'

import Button from '~components/button/button.vue'
import Badge, { BadgeVariant } from '~components/badge/badge.vue'

import { SurveyStatus, SurveyWithStats } from '~models/survey'

export default Vue.extend({
  components: {
    Button,
    Badge,
  },

  filters: {
    distanceToNow(timestamp: string | null): string {
      if (timestamp === null) {
        return '–'
      }
      const parsed = parseISO(timestamp)
      return `${formatDistanceToNow(parsed)} ago`
    },
    capitalize(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    inPercent(value: number | null): string {
      if (value === null) {
        return '–'
      }

      return `${value}%`
    },
  },

  computed: {
    surveys(): SurveyWithStats[] {
      return this.$store.state.surveys.surveys
    },
  },

  methods: {
    badgeVariantForStatus(status: SurveyStatus): BadgeVariant {
      return {
        [SurveyStatus.Draft]: BadgeVariant.Muted,
        [SurveyStatus.Live]: BadgeVariant.Success,
        [SurveyStatus.Paused]: BadgeVariant.Default,
      }[status]
    },
  },
})
</script>
