<template>
  <div class="w-7/12 mt-2">
    <p class="leading-none text-sm pb-2 block">
      Preview
    </p>
    <div class="flex flex-col bg-bg-100 border border-blue-border-100 rounded-lg pb-12">
      <div class="w-full border-b border-blue-border-100 py-2 px-2 mb-3">
        <svg width="64" height="16" viewBox="0 0 64 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'12'" v-bind:class="'outline-none align-text-bottom'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="8" cy="8" r="7.5" stroke="#C2CCD9"/><circle cx="32" cy="8" r="7.5" stroke="#C2CCD9"/><circle cx="56" cy="8" r="7.5" stroke="#C2CCD9"/></svg>
      </div>

      <div class="px-3">
        <div class="w-full border border-blue-border-100 py-2 px-4 rounded-md mb-6">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'inline outline-none align-text-bottom'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 7.5V6a4.5 4.5 0 10-9 0v1.5A1.5 1.5 0 003 9v6a1.5 1.5 0 001.5 1.5h9A1.5 1.5 0 0015 15V9a1.5 1.5 0 00-1.5-1.5zM6 6a3 3 0 016 0v1.5H6V6zm3.75 7.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h1.5z" fill="#8592A3"/></svg>
          <p class="inline text-sm">
            {{ survey.pageUrl || 'https://your.app' }}
          </p>
        </div>

        <!-- Preview / Importance -->
        <div v-if="survey.importanceQuestion" id="exaktWidget">
          <div class="widget-stage">
            <div class="exaktContentContainer">
              <div id="exactWidget-step-1" class="feedbackSelections">
                <div class="headline" :style="{color: widget.primaryTextColor}">
                  {{ survey.importanceQuestion }}
                </div>
                <div class="selections">
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-importance-1" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Not at all
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-importance-2" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Not really
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-importance-3" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Indifferent
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-importance-4" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Important
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-importance-5" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Very important
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="survey.exaktLink" class="exaktLinkContainer">
              <div class="link">
                <p>
                  <a href="https://exakt.io?ref=exaktwidget" target="_blank">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="exaktLogo"
                    >
                      <path
                        d="M0 5.20356V11.7964C4.78915e-05 12.1312 0.0882044 12.4601 0.255611 12.75C0.423018 13.0399 0.663779 13.2806 0.9537 13.448L6.66356 16.7445C6.95352 16.9119 7.28244 17 7.61726 17C7.95208 17 8.281 16.9119 8.57096 16.7445L14.2797 13.448C14.5696 13.2806 14.8104 13.0399 14.9778 12.75C15.1452 12.4601 15.2333 12.1312 15.2334 11.7964V5.20356C15.2333 4.86879 15.1452 4.53993 14.9778 4.25002C14.8104 3.96011 14.5696 3.71937 14.2797 3.55198L8.57096 0.255543C8.281 0.0881339 7.95208 0 7.61726 0C7.28244 0 6.95352 0.0881339 6.66356 0.255543L0.9537 3.55198C0.663779 3.71937 0.423018 3.96011 0.255611 4.25002C0.0882044 4.53993 4.78915e-05 4.86879 0 5.20356V5.20356Z"
                        fill="#0097FF"
                      />
                      <path
                        d="M3.13048 11.9134L8.80668 11.9056C9.04203 11.9045 9.27299 11.8418 9.47655 11.7237C9.68011 11.6056 9.84916 11.4362 9.96683 11.2324L12.7976 6.31239C12.8723 6.18255 12.9115 6.03533 12.9112 5.88555C12.9109 5.73576 12.8712 5.58869 12.796 5.45913C12.7209 5.32958 12.6129 5.22212 12.483 5.14756C12.353 5.07301 12.2058 5.03398 12.056 5.03443L6.38092 5.04228C6.14557 5.04331 5.9146 5.10601 5.71104 5.22413C5.50748 5.34225 5.33844 5.51166 5.22077 5.71548L2.38996 10.6321C2.31447 10.7619 2.27461 10.9094 2.27441 11.0596C2.27422 11.2098 2.31369 11.3573 2.38884 11.4874C2.46399 11.6174 2.57216 11.7253 2.70239 11.8001C2.83262 11.8749 2.98029 11.914 3.13048 11.9134V11.9134Z"
                        fill="white"
                      />
                      <path
                        d="M7.6178 10.5104C8.72824 10.5104 9.62843 9.6102 9.62843 8.49976C9.62843 7.38932 8.72824 6.48914 7.6178 6.48914C6.50736 6.48914 5.60718 7.38932 5.60718 8.49976C5.60718 9.6102 6.50736 10.5104 7.6178 10.5104Z"
                        fill="black"
                      />
                    </svg> Powered by Exakt </a>
                </p>
              </div>
            </div>
          </div>
          <div class="widget-visibility-controller">
            <div class="controller close">
              <svg
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8499 10.4401C11.9445 10.534 11.9978 10.6618 11.9978 10.7951C11.9978 10.9284 11.9445 11.0562 11.8499 11.1501L11.1499 11.8501C11.056 11.9448 10.9282 11.998 10.7949 11.998C10.6615 11.998 10.5337 11.9448 10.4399 11.8501L5.99985 7.4101L1.55985 11.8501C1.46597 11.9448 1.33817 11.998 1.20485 11.998C1.07153 11.998 0.943736 11.9448 0.849852 11.8501L0.149852 11.1501C0.055196 11.0562 0.00195312 10.9284 0.00195312 10.7951C0.00195312 10.6618 0.055196 10.534 0.149852 10.4401L4.58985 6.0001L0.149852 1.5601C0.055196 1.46621 0.00195313 1.33841 0.00195313 1.2051C0.00195313 1.07178 0.055196 0.94398 0.149852 0.850096L0.849852 0.150096C0.943736 0.0554401 1.07153 0.00219727 1.20485 0.00219727C1.33817 0.00219727 1.46597 0.0554401 1.55985 0.150096L5.99985 4.5901L10.4399 0.150096C10.5337 0.0554401 10.6615 0.00219727 10.7949 0.00219727C10.9282 0.00219727 11.056 0.0554401 11.1499 0.150096L11.8499 0.850096C11.9445 0.94398 11.9978 1.07178 11.9978 1.2051C11.9978 1.33841 11.9445 1.46621 11.8499 1.5601L7.40985 6.0001L11.8499 10.4401Z"
                  fill="#8592A3"
                />
              </svg>
            </div>
          </div>
        </div>

        <!-- Preview / Satisfaction -->
        <div v-if="survey.satisfactionQuestion" id="exaktWidget">
          <div class="widget-stage">
            <div class="exaktContentContainer">
              <div id="exactWidget-step-1" class="feedbackSelections">
                <div
                  class="headline"
                  :style="{color: widget.primaryTextColor}"
                >
                  {{ survey.satisfactionQuestion }}
                </div>
                <div class="selections">
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-satisfaction-1" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Hate it
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-satisfaction-2" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Pretty bad
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-satisfaction-3" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Okay
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-satisfaction-4" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Good
                    </div>
                  </div>
                  <div class="selection">
                    <div class="icon">
                      <div class="emoji-icon emoji-icon-lg emoji-icon-satisfaction-5" />
                    </div>
                    <div class="label" :style="{ color: widget.secondaryTextColor }">
                      Awesome
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="survey.exaktLink" class="exaktLinkContainer">
              <div class="link">
                <p>
                  <a href="https://exakt.io?ref=exaktwidget" target="_blank">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="exaktLogo"
                    >
                      <path
                        d="M0 5.20356V11.7964C4.78915e-05 12.1312 0.0882044 12.4601 0.255611 12.75C0.423018 13.0399 0.663779 13.2806 0.9537 13.448L6.66356 16.7445C6.95352 16.9119 7.28244 17 7.61726 17C7.95208 17 8.281 16.9119 8.57096 16.7445L14.2797 13.448C14.5696 13.2806 14.8104 13.0399 14.9778 12.75C15.1452 12.4601 15.2333 12.1312 15.2334 11.7964V5.20356C15.2333 4.86879 15.1452 4.53993 14.9778 4.25002C14.8104 3.96011 14.5696 3.71937 14.2797 3.55198L8.57096 0.255543C8.281 0.0881339 7.95208 0 7.61726 0C7.28244 0 6.95352 0.0881339 6.66356 0.255543L0.9537 3.55198C0.663779 3.71937 0.423018 3.96011 0.255611 4.25002C0.0882044 4.53993 4.78915e-05 4.86879 0 5.20356V5.20356Z"
                        fill="#0097FF"
                      />
                      <path
                        d="M3.13048 11.9134L8.80668 11.9056C9.04203 11.9045 9.27299 11.8418 9.47655 11.7237C9.68011 11.6056 9.84916 11.4362 9.96683 11.2324L12.7976 6.31239C12.8723 6.18255 12.9115 6.03533 12.9112 5.88555C12.9109 5.73576 12.8712 5.58869 12.796 5.45913C12.7209 5.32958 12.6129 5.22212 12.483 5.14756C12.353 5.07301 12.2058 5.03398 12.056 5.03443L6.38092 5.04228C6.14557 5.04331 5.9146 5.10601 5.71104 5.22413C5.50748 5.34225 5.33844 5.51166 5.22077 5.71548L2.38996 10.6321C2.31447 10.7619 2.27461 10.9094 2.27441 11.0596C2.27422 11.2098 2.31369 11.3573 2.38884 11.4874C2.46399 11.6174 2.57216 11.7253 2.70239 11.8001C2.83262 11.8749 2.98029 11.914 3.13048 11.9134V11.9134Z"
                        fill="white"
                      />
                      <path
                        d="M7.6178 10.5104C8.72824 10.5104 9.62843 9.6102 9.62843 8.49976C9.62843 7.38932 8.72824 6.48914 7.6178 6.48914C6.50736 6.48914 5.60718 7.38932 5.60718 8.49976C5.60718 9.6102 6.50736 10.5104 7.6178 10.5104Z"
                        fill="black"
                      />
                    </svg> Powered by Exakt </a>
                </p>
              </div>
            </div>
          </div>
          <div class="widget-visibility-controller">
            <div class="controller close">
              <svg
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8499 10.4401C11.9445 10.534 11.9978 10.6618 11.9978 10.7951C11.9978 10.9284 11.9445 11.0562 11.8499 11.1501L11.1499 11.8501C11.056 11.9448 10.9282 11.998 10.7949 11.998C10.6615 11.998 10.5337 11.9448 10.4399 11.8501L5.99985 7.4101L1.55985 11.8501C1.46597 11.9448 1.33817 11.998 1.20485 11.998C1.07153 11.998 0.943736 11.9448 0.849852 11.8501L0.149852 11.1501C0.055196 11.0562 0.00195312 10.9284 0.00195312 10.7951C0.00195312 10.6618 0.055196 10.534 0.149852 10.4401L4.58985 6.0001L0.149852 1.5601C0.055196 1.46621 0.00195313 1.33841 0.00195313 1.2051C0.00195313 1.07178 0.055196 0.94398 0.149852 0.850096L0.849852 0.150096C0.943736 0.0554401 1.07153 0.00219727 1.20485 0.00219727C1.33817 0.00219727 1.46597 0.0554401 1.55985 0.150096L5.99985 4.5901L10.4399 0.150096C10.5337 0.0554401 10.6615 0.00219727 10.7949 0.00219727C10.9282 0.00219727 11.056 0.0554401 11.1499 0.150096L11.8499 0.850096C11.9445 0.94398 11.9978 1.07178 11.9978 1.2051C11.9978 1.33841 11.9445 1.46621 11.8499 1.5601L7.40985 6.0001L11.8499 10.4401Z"
                  fill="#8592A3"
                />
              </svg>
            </div>
          </div>
        </div>

        <!-- Preview / Comment -->
        <div v-if="survey.feedbackQuestion" id="exaktWidget">
          <div class="widget-stage">
            <div class="exaktContentContainer">
              <div id="exactWidget-step-3" class="feedbackComment">
                <textarea
                  id=""
                  name=""
                  cols="30"
                  rows="10"
                  :placeholder="survey.feedbackQuestion"
                  :style="{color: widget.primaryTextColor}"
                />
                <div class="buttons">
                  <button
                    class="back"
                    :style="backButtonStyles"
                    @mouseenter="isHoveringBackButton = true"
                    @mouseleave="isHoveringBackButton = false"
                  >
                    Skip
                  </button>
                  <button
                    class="submit"
                    :style="submitButtonStyles"
                    @mouseenter="isHoveringSubmitButton = true"
                    @mouseleave="isHoveringSubmitButton = false"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div v-if="survey.exaktLink" class="exaktLinkContainer">
              <div class="link">
                <p>
                  <a href="https://exakt.io?ref=exaktwidget" target="_blank">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="exaktLogo"
                    >
                      <path
                        d="M0 5.20356V11.7964C4.78915e-05 12.1312 0.0882044 12.4601 0.255611 12.75C0.423018 13.0399 0.663779 13.2806 0.9537 13.448L6.66356 16.7445C6.95352 16.9119 7.28244 17 7.61726 17C7.95208 17 8.281 16.9119 8.57096 16.7445L14.2797 13.448C14.5696 13.2806 14.8104 13.0399 14.9778 12.75C15.1452 12.4601 15.2333 12.1312 15.2334 11.7964V5.20356C15.2333 4.86879 15.1452 4.53993 14.9778 4.25002C14.8104 3.96011 14.5696 3.71937 14.2797 3.55198L8.57096 0.255543C8.281 0.0881339 7.95208 0 7.61726 0C7.28244 0 6.95352 0.0881339 6.66356 0.255543L0.9537 3.55198C0.663779 3.71937 0.423018 3.96011 0.255611 4.25002C0.0882044 4.53993 4.78915e-05 4.86879 0 5.20356V5.20356Z"
                        fill="#0097FF"
                      />
                      <path
                        d="M3.13048 11.9134L8.80668 11.9056C9.04203 11.9045 9.27299 11.8418 9.47655 11.7237C9.68011 11.6056 9.84916 11.4362 9.96683 11.2324L12.7976 6.31239C12.8723 6.18255 12.9115 6.03533 12.9112 5.88555C12.9109 5.73576 12.8712 5.58869 12.796 5.45913C12.7209 5.32958 12.6129 5.22212 12.483 5.14756C12.353 5.07301 12.2058 5.03398 12.056 5.03443L6.38092 5.04228C6.14557 5.04331 5.9146 5.10601 5.71104 5.22413C5.50748 5.34225 5.33844 5.51166 5.22077 5.71548L2.38996 10.6321C2.31447 10.7619 2.27461 10.9094 2.27441 11.0596C2.27422 11.2098 2.31369 11.3573 2.38884 11.4874C2.46399 11.6174 2.57216 11.7253 2.70239 11.8001C2.83262 11.8749 2.98029 11.914 3.13048 11.9134V11.9134Z"
                        fill="white"
                      />
                      <path
                        d="M7.6178 10.5104C8.72824 10.5104 9.62843 9.6102 9.62843 8.49976C9.62843 7.38932 8.72824 6.48914 7.6178 6.48914C6.50736 6.48914 5.60718 7.38932 5.60718 8.49976C5.60718 9.6102 6.50736 10.5104 7.6178 10.5104Z"
                        fill="black"
                      />
                    </svg> Powered by Exakt </a>
                </p>
              </div>
            </div>
          </div>
          <div class="widget-visibility-controller">
            <div class="controller close">
              <svg
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8499 10.4401C11.9445 10.534 11.9978 10.6618 11.9978 10.7951C11.9978 10.9284 11.9445 11.0562 11.8499 11.1501L11.1499 11.8501C11.056 11.9448 10.9282 11.998 10.7949 11.998C10.6615 11.998 10.5337 11.9448 10.4399 11.8501L5.99985 7.4101L1.55985 11.8501C1.46597 11.9448 1.33817 11.998 1.20485 11.998C1.07153 11.998 0.943736 11.9448 0.849852 11.8501L0.149852 11.1501C0.055196 11.0562 0.00195312 10.9284 0.00195312 10.7951C0.00195312 10.6618 0.055196 10.534 0.149852 10.4401L4.58985 6.0001L0.149852 1.5601C0.055196 1.46621 0.00195313 1.33841 0.00195313 1.2051C0.00195313 1.07178 0.055196 0.94398 0.149852 0.850096L0.849852 0.150096C0.943736 0.0554401 1.07153 0.00219727 1.20485 0.00219727C1.33817 0.00219727 1.46597 0.0554401 1.55985 0.150096L5.99985 4.5901L10.4399 0.150096C10.5337 0.0554401 10.6615 0.00219727 10.7949 0.00219727C10.9282 0.00219727 11.056 0.0554401 11.1499 0.150096L11.8499 0.850096C11.9445 0.94398 11.9978 1.07178 11.9978 1.2051C11.9978 1.33841 11.9445 1.46621 11.8499 1.5601L7.40985 6.0001L11.8499 10.4401Z"
                  fill="#8592A3"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Survey } from '~models/survey'
import { Widget } from '~models/widget'

export default Vue.extend({
  props: {
    survey: {
      type: Object as PropType<Survey>,
      required: true,
    },
    widget: {
      type: Object as PropType<Widget>,
      required: true,
    },
  },

  data(): {
    isHoveringBackButton: boolean
    isHoveringSubmitButton: boolean
  } {
    return {
      isHoveringBackButton: false,
      isHoveringSubmitButton: false,
    }
  },

  computed: {
    submitButtonStyles(): any {
      if (this.widget === undefined) {
        return {}
      }

      return {
        color: this.isHoveringSubmitButton ? this.widget.primaryButtonHoverTextColor : this.widget.primaryButtonTextColor,
        background: this.isHoveringSubmitButton ? this.widget.primaryButtonHoverBackgroundColor : this.widget.primaryButtonBackgroundColor,
        borderRadius: this.widget.borderRadius,
      }
    },
    backButtonStyles(): any {
      if (this.widget === undefined) {
        return {}
      }

      return {
        color: this.isHoveringBackButton ? this.widget.secondaryButtonHoverTextColor : this.widget.secondaryButtonTextColor,
        background: this.isHoveringBackButton ? this.widget.secondaryButtonHoverBackgroundColor : this.widget.secondaryButtonBackgroundColor,
        borderRadius: this.widget.borderRadius,
      }
    },
  },
})
</script>

<style scoped>
.spinner {
  position: absolute;
  top: 7px;
  left: 7px;
}

@media (max-width: 767px) {
  #exaktWidget {
    bottom: 10px;
  }
}

#exaktWidget {
  margin: 30px auto;
  padding: 0 10px;
  max-width: 550px;
  width: 100%;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.widget-stage {
  background: white;
  -webkit-box-shadow: 0px 10px 90px rgba(28, 30, 36, 0.15);
  box-shadow: 0px 10px 90px rgba(28, 30, 36, 0.15);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.widget-visibility-controller {
  margin-top: 30px;
  display: none;
}

.widget-visibility-controller .controller {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #FFFFFF;
  position: relative;
  -webkit-box-shadow: 0px 4px 40px rgba(28, 30, 36, 0.15);
  box-shadow: 0px 4px 40px rgba(28, 30, 36, 0.15);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.widget-visibility-controller .close svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  opacity: 0.6;
  transition: all 150ms;
}

.widget-visibility-controller .close:hover svg {
  opacity: 1;
}

.slide-top-enter-active {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-top-leave-active {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
	animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .feedbackSelections {
    padding: 20px 15px 40px;
  }

  .feedbackComment {
    padding: 20px;
  }

  .feedbackCompleted {
    padding-top: 50px;
  }
}

.exaktContentContainer {
  height: 170px;
}

.exaktLinkContainer {
  padding: 12px 0px 15px;
  background: #F9FAFC;
  border-radius: 0px 0px 8px 8px;
}

.exaktLinkContainer p {
  text-align: center;
  margin: 0;
}

.exaktLinkContainer a {
  font-size: 12px;
  color: #3E4856;
  text-decoration: none;
  padding: 6px 8px;
  border-radius: 6px;
  transition: all 150ms;
}

.exaktLinkContainer a:hover {
  background: rgba(10, 175, 255, 0.1);
  color: #1F2D3F;
}

.exaktLinkContainer a:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 151, 255, 0.3);
}

.exaktLinkContainer img {
  vertical-align: bottom;
  margin-right: 8px;
  width: 14px;
}

.center {
  margin: 0 auto;
}

.exaktLogo {
  display: inline-block;
  vertical-align: middle;
}

.feedbackSelections {
  padding: 40px 20px 40px;
}

.feedbackSelections .headline {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding-bottom: 20px;
}

.feedbackComment {
  padding: 30px 30px 20px;
}

.feedbackComment textarea {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  border: 0;
  resize: none;
  line-height: 1.35;
  color: #1F2D3F;
  font-size: 14px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.feedbackComment textarea:focus {
  outline: none;
}

.feedbackComment textarea::placeholder {
  color: #C2CCD9;
}

.feedbackComment .buttons {
  width: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  ms-flex-justify-content: flex-end;
  justify-content: flex-end;
}

.feedbackComment .buttons button {
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  padding: 10px 14px;
  transition: all 75ms;
}

.feedbackComment .buttons button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 151, 255, 0.3);
}

.feedbackComment .back {
  display: inline-block;
  background: #E5E9F1;
}

.feedbackComment .submit {
  display: inline-block;
  color: #FFFFFF;
  background: #0AAFFF;
  margin-left: 10px;
}

.feedbackCompleted {
  padding-top: 50px;
  text-align: center;
  height: 116px;
}

.feedbackCompleted .icon {
  margin-bottom: 10px;
}

.feedbackCompleted .message {
  font-size: 14px;
}

.selections {
  display: -moz-flex;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  ms-flex-justify-content: center;
  justify-content: center;
}

.selection {
  padding: 5px 15px;
  text-align: center;
  position: relative;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.selection:hover .icon {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  mix-blend-mode: normal;
}

.selection:hover .label {
  opacity: 1;
  bottom: -12px;
}

.icon {
  mix-blend-mode: luminosity;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 75ms ease-in-out !important;
  -o-transition: all 75ms ease-in-out !important;
  transition: all 75ms ease-in-out !important;
  mix-blend-mode: luminosity;
}

.label {
  color: #8592A3;
  font-size: 9px;
  line-height: 10px;
  position: absolute;
  left: 0;
  bottom: -16px;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 75ms ease-in-out !important;
  -o-transition: all 75ms ease-in-out !important;
  transition: all 75ms ease-in-out !important;
}

.emoji-icon {
  vertical-align: top;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../../images/emojis.png);
}

.emoji-icon-md {
  width: 24px;
  height: 25px;
  background-size: 24px;
}

.emoji-icon-md.emoji-icon-importance-1 {
  background-position: 0 0;
}

.emoji-icon-md.emoji-icon-importance-2 {
  background-position: 0 -25px;
}

.emoji-icon-md.emoji-icon-importance-3 {
  background-position: 0 -50px;
}

.emoji-icon-md.emoji-icon-importance-4 {
  background-position: 0 -75px;
}

.emoji-icon-md.emoji-icon-importance-5 {
  background-position: 0 -100px;
}

.emoji-icon-md.emoji-icon-satisfaction-1 {
  background-position: 0 -126px;
}

.emoji-icon-md.emoji-icon-satisfaction-2 {
  background-position: 0 -151px;
}

.emoji-icon-md.emoji-icon-satisfaction-3 {
  background-position: 0 -176px;
}

.emoji-icon-md.emoji-icon-satisfaction-4 {
  background-position: 0 -201px;
}

.emoji-icon-md.emoji-icon-satisfaction-5 {
  background-position: 0 -226px;
}

.emoji-icon-lg {
  width: 38px;
  height: 40px;
  background-size: 38px;
}

.emoji-icon-lg.emoji-icon-importance-1 {
  background-position: 0 0;
}

.emoji-icon-lg.emoji-icon-importance-2 {
  background-position: 0 -40px;
}

.emoji-icon-lg.emoji-icon-importance-3 {
  background-position: 0 -80px;
}

.emoji-icon-lg.emoji-icon-importance-4 {
  background-position: 0 -119px;
}

.emoji-icon-lg.emoji-icon-importance-5 {
  background-position: 0 -159px;
}

.emoji-icon-lg.emoji-icon-satisfaction-1 {
  background-position: 0 -199px;
}

.emoji-icon-lg.emoji-icon-satisfaction-2 {
  background-position: 0 -239px;
}

.emoji-icon-lg.emoji-icon-satisfaction-3 {
  background-position: 0 -279px;
}

.emoji-icon-lg.emoji-icon-satisfaction-4 {
  background-position: 0 -318px;
}

.emoji-icon-lg.emoji-icon-satisfaction-5 {
  background-position: 0 -358px;
}
</style>
