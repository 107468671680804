/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import { Module } from 'vuex'
import { unionBy } from 'lodash'
import axios from '~plugins/axios'

import { Reply } from '~models/reply'

import { RootState, ModuleContext } from '~plugins/store'

export interface State {
  replies?: Reply[]
}

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    replies: undefined,
  },
  getters: {
    repliesForSurvey: (state: State) => (surveyId: string): Reply[] => (state.replies || []).filter((reply: Reply) => reply.surveyId === surveyId),
  },
  actions: {
    async fetch({ commit }: ModuleContext<State>, { surveyId = '', page = 1 }: { surveyId: string, page: number } = { surveyId: '', page: 1 }): Promise<void> {
      const { data: replies } = await axios.get(`replies?page=${page}&survey_id=${surveyId}`)
      commit('APPEND_REPLIES', replies)
    },
  },
  mutations: {
    APPEND_REPLIES(state: State, replies: Reply[]): void {
      if (state.replies === undefined) {
        state.replies = replies
      } else {
        state.replies = unionBy([...state.replies, ...replies], 'id').sort((a, b) => b.createdAtUnix - a.createdAtUnix)
      }
    },
  },
}

export default store
