<template>
  <div class="flex items-center border border-blue-border-100 rounded-xl h-24 pr-6">
    <div class="flex-shrink-0 w-24 flex justify-center">
      <SkeletonBox v-if="loading" width="60px" height="3em" />
      <img v-else-if="!$slots.icon" :src="require(`../../images/icons/${icon}.svg`)">
      <slot v-else name="icon" />
    </div>

    <div class="pr-6">
      <div v-if="loading">
        <p class="leading-none pb-2">
          <SkeletonBox width="200px" />
        </p>
        <p class="leading-none text-sm text-navy-300">
          <SkeletonBox width="200px" />
        </p>
      </div>

      <slot v-else />
    </div>
    <div class="ml-auto">
      <slot v-if="!loading" name="action" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import SkeletonBox from '~components/skeleton-box/skeleton-box.vue'

export default Vue.extend({
  components: {
    SkeletonBox,
  },

  props: {
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
