<template>
  <div class="px-16 pb-20 pt-10">
    <div class="container-2">
      <Navigation />
      <div class="pt-4 pb-16">
        <!--p class="pb-4">
          Profile
        </p>
        <div class="pb-6">
          <avatar-selector v-model="avatarUrl" />
        </div-->

        <form class="max-w-2xl" @submit.prevent="submit">
          <Alert
            v-if="userUpdated"
            variant="success"
            class="mb-4"
          >
            Your personal info has been updated
          </Alert>

          <div class="mb-6">
            <FormInput
              v-model="email"
              type="email"
              label="Email address"
              disabled
            />
          </div>
          <div class="mb-6">
            <FormInput
              v-model="company"
              type="text"
              label="Company name"
            />
          </div>
          <div class="mb-6">
            <FormInput
              v-model="firstName"
              type="text"
              label="First name"
            />
          </div>
          <div class="mb-6">
            <FormInput
              v-model="lastName"
              type="text"
              label="Last name"
            />
          </div>
          <Button
            class="btn-primary px-4 py-3"
            @click.prevent="submit"
          >
            Save changes
          </Button>
        </form>

        <div class="max-w-2xl">
          <p class="mt-10 mb-6">
            Password
          </p>

          <Alert
            v-if="passwordSuccess"
            variant="success"
            class="mb-4"
          >
            Password successfully updated
          </Alert>

          <div v-if="!showChangePassword">
            <Button
              variant="outline"
              class="flex items-center mr-3"
              @click.prevent="showChangePassword = true"
            >
              <div class="flex-shrink-0 mr-2">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 8.333V6.667a5 5 0 00-10 0v1.666c-.92 0-1.666.746-1.666 1.667v6.667c0 .92.746 1.666 1.667 1.666h10c.92 0 1.666-.746 1.666-1.666V10c0-.92-.746-1.667-1.666-1.667zM6.668 6.667a3.333 3.333 0 016.667 0v1.666H6.667V6.667zm6.25 5.833a.417.417 0 00-.416.417v.833c0 .23.186.417.416.417h.834c.23 0 .416-.187.416-.417v-.833a.417.417 0 00-.416-.417h-.834zm-3.75.417c0-.23.187-.417.417-.417h.833c.23 0 .417.187.417.417v.833c0 .23-.187.417-.417.417h-.833a.417.417 0 01-.417-.417v-.833zm-3.333 0v.833c0 .23.187.417.417.417h.833c.23 0 .417-.187.417-.417v-.833a.417.417 0 00-.417-.417h-.833a.417.417 0 00-.417.417z" fill="#8592A3"/></svg>
              </div>
              Change password
            </Button>
          </div>

          <div v-if="showChangePassword">
            <Alert
              v-if="passwordError"
              variant="error"
              class="mb-4"
            >
              Make sure your current password is correct and your new password is at least 8 characters long
            </Alert>

            <div class="mb-6">
              <FormInput
                v-model="passwordParams.currentPassword"
                type="password"
                value=""
                label="Current password"
              />
            </div>
            <div class="mb-6">
              <FormInput
                v-model="passwordParams.password"
                type="password"
                value=""
                label="New password"
              />
            </div>
            <div class="mb-6">
              <FormInput
                v-model="passwordParams.passwordConfirmation"
                type="password"
                value=""
                label="Confirm new password"
              />
            </div>
            <div class="flex">
              <Button
                class="btn-primary px-4 py-3 mr-4"
                :disabled="passwordParams.password.length < 8 || passwordParams.password !== passwordParams.passwordConfirmation"
                @click.prevent="changePassword"
              >
                Change password
              </Button>
              <Button
                variant="outline"
                class="flex items-center mr-3"
                @click.prevent="showChangePassword = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import Vue from 'vue'

import Navigation from './navigation.vue'
import AvatarSelector from '~components/avatar-selector/avatar-selector.vue'
import FormInput from '~components/form/input.vue'
import Alert from '~components/alert/alert.vue'
import Button from '~components/button/button.vue'

export default Vue.extend({
  components: {
    Navigation,
    Alert,
    AvatarSelector,
    FormInput,
    Button,
  },

  data() {
    return {
      showChangePassword: false,
      updatedAvatarUrl: null,
      error: null,
      fieldErrors: {
        inviteEmail: false,
      },
      passwordParams: {
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
      },
      inviteEmail: null,
      teamMembers: [],
      showInviteEmailForm: false,
      firstName: '',
      lastName: '',
      company: '',
      userUpdated: false,
      passwordError: false,
      passwordSuccess: false,
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    email() {
      return this.currentUser.email
    },
    avatarUrl: {
      get() {
        return this.$store.state.auth.user.avatarUrl
      },

      async set(file) {
        try {
          await this.$store.dispatch('auth/updateAvatar', file)
        } catch (err) {
          this.error = 'There was an error updating your avatar'
        }
      },
    },
  },

  watch: {
    currentUser: {
      immediate: true,
      handler() {
        if (this.currentUser) {
          this.firstName = this.currentUser.firstName
          this.lastName = this.currentUser.lastName
          this.company = this.currentUser.account.company
        }
      },
    },
  },

  methods: {
    async changePassword() {
      this.passwordError = false
      this.passwordSuccess = false

      try {
        await this.$store.dispatch('auth/updatePassword', this.passwordParams)
        this.showChangePassword = false
        this.passwordSuccess = true
      } catch (err) {
        this.passwordError = true
        Sentry.captureException(err)
        throw err
      }
    },

    async submit() {
      try {
        await this.$store.dispatch('auth/updateUser', { firstName: this.firstName, lastName: this.lastName, account: { company: this.company } })
        this.userUpdated = true

        setTimeout(() => {
          this.userUpdated = false
        }, 5000)
      } catch (err) {
        Sentry.captureException(err)
        throw err
      }
    },
  },
})
</script>
