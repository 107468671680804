<template>
  <div class="avatar_component w-20 h-20 overflow-hidden rounded-full relative">
    <img
      :src="value"
      :alt="alt"
      class="w-20 h-20 rounded-full"
    >
    <div class="edit absolute left-0 top-0 z-10 w-full h-full cursor-pointer hidden">
      <label
        for="avatar-file-picker"
        class="ico z-20 relative w-full h-full flex items-center justify-center cursor-pointer"
      >
        <svg width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.9.44l.66.66A1.5 1.5 0 0119 2.16v.76A1.5 1.5 0 0118.56 4l-1.85 1.82a.5.5 0 01-.71 0l-2.81-2.85a.5.5 0 010-.7L15 .44A1.5 1.5 0 0116.08 0h.76a1.5 1.5 0 011.06.44zM14.81 7.7a.5.5 0 000-.7l-2.86-2.82a.5.5 0 00-.71 0l-8.07 8.07a2 2 0 00-.45.68L1 17.27a.5.5 0 00.19.62.5.5 0 00.54.11l4.34-1.76a2 2 0 00.68-.45l8.06-8.09z" fill="#fff"/></svg>
      </label>
      <div class="bg z-10 absolute left-0 top-0 w-full h-full bg-black opacity-50" />
    </div>
    <input
      id="avatar-file-picker"
      type="file"
      hidden
      accept=".jpg,.jpeg,.gif,.png,.svg,image/*"
      @change="update"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'Avatar',
    },
  },

  methods: {
    update(e) {
      this.$emit('input', e.target.files[0])
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar_component {
  &:hover {
    .edit {
      display: block;
    }
  }
}
</style>
