<template>
  <div
    v-if="billingStatus === BillingStatus.PAYING"
    class="mb-12"
  >
    <div class="flex justify-between items-center mb-5">
      <div class="mr-6">
        <p class="pb-1">
          Next Payment
        </p>
        <p class="text-navy-300 text-sm">
          Your plan will renew on {{ parseDate(currentPeriodEnd) }} for {{ product.usdCentsPrice | formatMoneyValue }} (taxes included) per month.
        </p>
      </div>

      <div v-if="product.interval === 'month'">
        <Button
          variant="primary"
          @click="isYearlyModalOpen = true"
        >
          Switch to yearly
        </Button>
      </div>
      <div v-if="product.interval === 'year'">
        <Button
          variant="outline"
          @click="isMonthlyModalOpen = true"
        >
          Switch to monthly
        </Button>
      </div>
    </div>

    <Alert
      v-if="product.interval === 'month'"
      variant="info"
    >
      Get two months free when you switch to yearly billing
    </Alert>

    <Modal
      v-if="isYearlyModalOpen"
      title="Switch to yearly billing"
      cancel-button-text="Cancel"
      :show-cancel-button="true"
      @close="isYearlyModalOpen = false"
    >
      <template v-slot:default>
        <p class="text-sm leading-snug">
          Switch to yearly billing and save 2 months.
        </p>

        <div class="mt-6">
          <div class="mb-8">
            <label class="flex items-start justify-between cursor-pointer leading-none">
              <div class="flex items-center mr-4">
                <input
                  type="radio"
                  class="form-radio text-blue cursor-pointer flex-shrink-0"
                  name="radio1"
                  checked="checked"
                >
                <span class="ml-2 text-sm">Yearly billing</span>
              </div>
              <div class="text-sm text-right">
                {{ product.usdCentsPrice | formatMoneyValue }} / month
                <span class="block text-xs text-navy-300 pt-2">{{ yearlyProduct.usdCentsPrice | formatMoneyValue }} / year</span>
              </div>
            </label>
          </div>
        </div>
      </template>

      <template v-slot:action>
        <Spinner v-if="loading" />
        <Button
          v-else
          variant="primary"
          @click="switchPlan(yearlyProduct)"
        >
          Confirm change
        </Button>
      </template>
    </Modal>

    <Modal
      v-if="isMonthlyModalOpen"
      title="Switch to monthly billing"
      cancel-button-text="Cancel"
      :show-cancel-button="true"
      @close="isMonthlyModalOpen = false"
    >
      <template v-slot:default>
        <p class="text-sm leading-snug">
          Switch to monthly billing.
        </p>

        <div class="mt-6">
          <div class="mb-8">
            <label class="flex items-start justify-between cursor-pointer leading-none">
              <div class="flex items-center mr-4">
                <input
                  type="radio"
                  class="form-radio text-blue cursor-pointer flex-shrink-0"
                  name="radio1"
                  checked="checked"
                >
                <span class="ml-2 text-sm">Monthly billing</span>
              </div>
              <div class="text-sm text-right">
                <span class="block text-xs text-navy-300 pt-2">{{ monthlyProduct.usdCentsPrice | formatMoneyValue }} / month</span>
              </div>
            </label>
          </div>
        </div>
      </template>

      <template v-slot:action>
        <Spinner v-if="loading" />
        <Button
          v-else
          variant="primary"
          @click="switchPlan(monthlyProduct)"
        >
          Confirm change
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { parseJSON, format } from 'date-fns'

import formatMoneyValue from '~utils/format-money-value'

import Button from '~components/button/button.vue'
import Alert from '~components/alert/alert.vue'
import Modal from '~components/modal/modal.vue'
import Spinner from '~components/spinner/spinner.vue'

import { Product } from '~models/product'
import { BillingStatus } from '~models/billing-status'

export default Vue.extend({
  components: {
    Button,
    Alert,
    Modal,
    Spinner,
  },

  filters: {
    formatMoneyValue,
  },

  data() {
    return {
      BillingStatus,
      isYearlyModalOpen: false,
      isMonthlyModalOpen: false,
    }
  },

  computed: {
    loading(): boolean {
      return this.$store.getters['billing/loading']
    },
    currentPeriodEnd(): string {
      return this.$store.getters['billing/currentPeriodEnd']
    },
    product(): Product {
      return this.$store.getters['billing/currentProduct']
    },
    yearlyProduct(): Product {
      return this.$store.getters['billing/yearlyProduct']
    },
    monthlyProduct(): Product {
      return this.$store.getters['billing/monthlyProduct']
    },
    billingStatus(): BillingStatus | undefined {
      return this.$store.getters['billing/billingStatus']
    },
  },

  methods: {
    parseDate(date: string) {
      return date ? format(parseJSON(date), 'dd LLLL yyyy') : ''
    },
    async switchPlan(plan: Product) {
      try {
        await this.$store.dispatch('billing/updateProduct', plan)
      } catch (error) {
        // show error
      } finally {
        this.isYearlyModalOpen = false
        this.isMonthlyModalOpen = false
      }
    },
  },
})
</script>
