<template>
  <div :class="emojiClasses" :style="{opacity: opacity}" />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      validator: (type) => ['importance', 'satisfaction'].includes(type),
    },
    happiness: {
      type: Number,
      required: true,
      validator: (happiness) => [1, 2, 3, 4, 5].includes(happiness),
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (size) => ['medium', 'large'].includes(size),
    },
    score: {
      type: Number,
      required: false,
      default: 1,
      validator: (score) => score >= 0 && score <= 1,
    },
  },

  computed: {
    emojiClasses() {
      return [
        'emoji-icon',
        this.size === 'large' ? 'emoji-icon-lg' : 'emoji-icon-md',
        `emoji-icon-${this.type}-${this.happiness}`,
        this.active ? '' : 'inactive',
      ]
    },
    opacity() {
      if (this.score === 1) {
        return 1
      }

      return 0.5 + this.score / 2
    },
  },
})
</script>

<style lang="scss" scoped>
.emoji-icon {
  vertical-align: top;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../../images/emoji/sprites.png);
}

.emoji-icon-md {
  width: 24px;
  height: 25px;
  background-size: 24px;

  &.emoji-icon-importance-1 {
    background-position: 0 0;
  }

  &.emoji-icon-importance-2 {
    background-position: 0 -25px;
  }

  &.emoji-icon-importance-3 {
    background-position: 0 -50px;
  }

  &.emoji-icon-importance-4 {
    background-position: 0 -75px;
  }

  &.emoji-icon-importance-5 {
    background-position: 0 -100px;
  }

  &.emoji-icon-satisfaction-1 {
    background-position: 0 -126px;
  }

  &.emoji-icon-satisfaction-2 {
    background-position: 0 -151px;
  }

  &.emoji-icon-satisfaction-3 {
    background-position: 0 -176px;
  }

  &.emoji-icon-satisfaction-4 {
    background-position: 0 -201px;
  }

  &.emoji-icon-satisfaction-5 {
    background-position: 0 -226px;
  }
}

.emoji-icon-lg {
  width: 38px;
  height: 40px;
  background-size: 38px;

  &.emoji-icon-importance-1 {
    background-position: 0 0;
  }

  &.emoji-icon-importance-2 {
    background-position: 0 -40px;
  }

  &.emoji-icon-importance-3 {
    background-position: 0 -80px;
  }

  &.emoji-icon-importance-4 {
    background-position: 0 -119px;
  }

  &.emoji-icon-importance-5 {
    background-position: 0 -159px;
  }

  &.emoji-icon-satisfaction-1 {
    background-position: 0 -199px;
  }

  &.emoji-icon-satisfaction-2 {
    background-position: 0 -239px;
  }

  &.emoji-icon-satisfaction-3 {
    background-position: 0 -279px;
  }

  &.emoji-icon-satisfaction-4 {
    background-position: 0 -318px;
  }

  &.emoji-icon-satisfaction-5 {
    background-position: 0 -358px;
  }
}
</style>
