<template>
  <div>

    <Spinner v-if="loadingStripe" />
    <div
      v-else
      id="card-element"
      ref="cardElement"
      class="card-element"
    />

    <div class="flex pt-4">
      <Spinner v-if="updateInProgress" />
      <div v-else>
        <Button
          class="btn-primary px-4 py-3 mr-2"
          @click="updateCard"
        >
          {{ actionButtonText }}
        </Button>

        <Button
          v-if="showCancelButton"
          class="py-3 px-4"
          variant="outline"
          @click="$emit('close')"
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import loadScript from '~utils/load-script'

import Button from '~components/button/button.vue'
import Spinner from '~components/spinner/spinner.vue'

export default Vue.extend({
  components: {
    Button,
    Spinner,
  },

  props: {
    showCancelButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    actionButtonText: {
      type: String,
      required: true,
    },
  },

  data() : { card: any, stripe: any, loadingStripe: boolean, updateInProgress: boolean, error: any } {
    return {
      loadingStripe: false,
      updateInProgress: false,
      error: null,
      stripe: {},
      card: {},
    }
  },

  computed: {

  },

  async mounted() {
    await this.configureStripe()

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', (event: any) => {
      if (event.error) {
        this.error = event.error.message
      } else {
        this.error = null
      }
    })

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount(this.$refs.cardElement)
  },

  methods: {
    async configureStripe() {
      this.loadingStripe = true

      if (!window.Stripe) {
        await loadScript('https://js.stripe.com/v3/')
      }

      const stripe = window.Stripe(window.STRIPE_PUBLISHABLE_KEY)

      const card = stripe.elements().create('card', {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: '#1F2D3F',
            color: '#1F2D3F',
            lineHeight: '45px',
            fontFamily: "'Graphik', sans-serif",
            fontSize: '16px',
            fontWeight: 400,
            '::placeholder': {
              color: 'rgba(133, 146, 163, 0.5)',
              iconColor: 'rgba(133, 146, 163, 0.5)',
            },
          },
        },
      })

      this.stripe = stripe
      this.card = card
      this.loadingStripe = false
    },
    async updateCard() {
      this.updateInProgress = true
      const result = await this.stripe.createToken(this.card)

      if (result.error) {
        this.error = result.error.message
        this.updateInProgress = false

        return
      }

      try {
        await this.$store.dispatch('billing/updateCard', { stripeToken: result.token.id })
        this.$emit('cardSuccessfullyUpdated')
      } catch (err) {
        console.error(err)
      } finally {
        this.updateInProgress = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
  .StripeElement {
    box-sizing: border-box;
    height: 3rem;
    padding: 0 12px;
    border: 1px solid #E5E9F1;
    border-radius: 0.375rem;
    background-color: white;
  }
</style>
