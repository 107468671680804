export enum SurveyStatus {
  Draft = 'draft',
  Live = 'live',
  Paused = 'paused',
}

export interface SurveyParams {
  status: SurveyStatus
  frequencyInDays: number
  name: string
  numberOfVisitsToShow: number
  pageUrl: string
  importanceQuestion: string
  satisfactionQuestion: string
  feedbackQuestion: string
  secondsToShow: number
  exaktLink: boolean
}

export interface Survey extends SurveyParams {
  id: string
  pageHost: string
  pagePath: string
  createdAt: string
}

export interface SurveyWithStats extends Survey {
  replyCount: number
  responseRate: number
  avgImportanceScore: number
  avgSatisfactionScore: number
  opportunityScore: number
  maxCreatedAt: string
}
