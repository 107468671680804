import Vue from 'vue'
import axios, { AxiosTransformer } from 'axios' // Import AxiosTransformer directly
import VueAxios from 'vue-axios'
import { camelizeKeys, decamelizeKeys } from '~vue/utils/humps'

// Some defaults
axios.defaults.baseURL = process.env.API_BASE_URL || 'http://api.exakt.test:3000'
axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['Content-Type'] = 'application/json'

// dark magic to satisfy TypeScript
function ensureArray(value: AxiosTransformer | AxiosTransformer[] | undefined): AxiosTransformer[] {
  if (Array.isArray(value)) {
    return value
  }
  if (value) {
    return [value]
  }
  return []
}

// Transform under_score <-> camelCase
axios.defaults.transformRequest = [
  (data) => decamelizeKeys(data),
  ...ensureArray(axios.defaults.transformRequest),
]

axios.defaults.transformResponse = [
  ...ensureArray(axios.defaults.transformResponse),
  (data) => camelizeKeys(data),
]

// If the user has an authToken stored, use it for every request
const authToken = localStorage.getItem('authToken')
if (authToken) {
  axios.defaults.headers.common.authorization = authToken
}

Vue.use(VueAxios, axios)

export default axios
