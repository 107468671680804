import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '../stylesheets/application.scss'

import Vue from 'vue'
import VueScreen from 'vue-screen'

import App from '../layouts/app.vue'

import axios from '~plugins/axios'
import router from '~plugins/router'
import store from '~plugins/store'
import useSentry from '~plugins/sentry'

Vue.use(VueScreen, 'tailwind')

useSentry(Vue)

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    created() {
      // Logout on 401 response
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401 && error.config.url.indexOf('users/sign_in') === -1) {
            this.$router.push('/')
            this.$store.dispatch('auth/logout')
          }
          return Promise.reject(error)
        },
      )
    },
    render: (h) => h(App),
    router,
    store,
  }).$mount()
  document.body.appendChild(app.$el)
})
