/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/browser'
import { Module } from 'vuex'
import axios from '~plugins/axios'

import { Widget } from '~models/widget'

import { RootState, ModuleContext } from '~plugins/store'

export interface State {
  widget?: Widget
}

const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    widget: undefined,
  },
  actions: {
    async fetch({ commit }: ModuleContext<State>): Promise<void> {
      try {
        const { data } = await axios.get<Widget>('widget')
        commit('SET_WIDGET', data)
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    async update({ commit }: ModuleContext<State>, params: Partial<Widget>): Promise<void> {
      try {
        const { data } = await axios.put<Widget>('widget', params)
        commit('SET_WIDGET', data)
      } catch (err) {
        Sentry.captureException(err)
      }
    },
  },
  mutations: {
    SET_WIDGET(state: State, widget: Widget): void {
      state.widget = widget
    },
  },
}

export default store
