<template>
  <div class="flex flex-col px-6 py-4 border border-blue-border-100 rounded-xl mb-4">
    <div class="m-2 items-center flex justify-between">
      <div class="flex items-center w-3/5">
        <ScoreCircle
          v-if="reply.importanceScore && reply.satisfactionScore"
          class="relative"
          size="small"
          :total-score="totalScore"
        />

        <div class="ml-4 w-4/5 pr-4">
          <p class="block text-sm leading-none">
            {{ reply.customerIdentifier }}
          </p>
          <p class="block text-xs text-navy-300 truncate">
            {{ reply.createdAt | distanceToNow }} ago · {{ reply.survey.name }}
          </p>
        </div>
      </div>

      <div class="flex justify-between w-2/5">
        <div v-if="reply.importanceScore">
          <Emoji
            type="importance"
            :happiness="reply.importanceScore"
          />
          <span class="text-xs inline-block rounded-6px px-2 py-1 text-navy-300">
            <span class="text-navy-800">{{ reply.importanceScore }}</span> Importance
          </span>
        </div>
        <div v-if="reply.satisfactionScore">
          <Emoji
            type="satisfaction"
            :happiness="reply.satisfactionScore"
          />
          <span class="text-xs inline-block rounded-6px px-2 py-1 text-navy-300">
            <span class="text-navy-800">{{ reply.satisfactionScore }}</span> Satisfaction
          </span>
        </div>
      </div>
    </div>

    <div v-if="reply.comment" class="m-2">
      <p class="text-sm">
        {{ reply.comment }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { parseISO, formatDistanceToNow } from 'date-fns'

import Emoji from '~components/emoji/emoji.vue'
import ScoreCircle from './score-circle.vue'

import { Reply } from '~models/reply'

export default Vue.extend({
  components: {
    Emoji,
    ScoreCircle,
  },

  filters: {
    distanceToNow(timestamp: string): string {
      const parsed = parseISO(timestamp)
      return formatDistanceToNow(parsed)
    },
  },

  props: {
    reply: {
      type: Object as PropType<Reply>,
      required: true,
    },
  },

  computed: {
    totalScore(): number {
      return this.reply.importanceScore + Math.max(this.reply.importanceScore - this.reply.satisfactionScore, 0)
    },
  },
})
</script>
