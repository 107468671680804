<template>
  <div class="px-16 py-20">
    <div v-if="token" class="max-w-xs mx-auto">
      <div class="pb-12 text-center">
        <h1 class="pb-1 text-2xl font-medium">
          Create new password
        </h1>
      </div>

      <div class="relative mb-3">
        <FormInput
          v-model.trim="newPassword"
          :type="showPasswordText ? 'text' : 'password'"
          placeholder="Password"
          class="reveal_password_input"
        />
        <div v-if="newPassword.length > 0" class="absolute top-0 bottom-0 flex items-center pl-3 my-auto left-full">
          <div class="text-xs cursor-pointer trigger text-navy-300 hover:text-black" @click.prevent="showPasswordText = !showPasswordText">
            {{ showPasswordText ? 'Hide' : 'Show' }}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <FormInput
          v-model.trim="newPasswordConfirmation"
          :type="showPasswordText ? 'text' : 'password'"
          placeholder="Confirm password"
          class="reveal_password_input"
        />

        <p v-if="passwordError" class="pt-1 text-xs text-red-900">
          Passwords don't match
        </p>
      </div>
      <div class="pt-3">
        <Button class="w-full" @click.prevent="submitNewPassword">
          Create new password
        </Button>
      </div>
    </div>

    <div v-else class="max-w-xs mx-auto">
      <div v-if="requested" class="pb-12 text-center">
        <h1 class="pb-1 text-2xl">
          We've emailed you a password reset link!
        </h1>
        <p class="mt-4 text-xs">
          <RouterLink
            to="/users/sign-in"
            class="text-navy-300 hover:text-black"
          >
            Back to Sign in
          </RouterLink>
        </p>
      </div>
      <div v-else class="pb-12 text-center">
        <h1 class="pb-1 text-2xl font-medium">
          Forgot your password?
        </h1>
        <p class="mx-auto mb-4 text-xs max-w-50 text-navy-300">
          Enter your email and we'll send you instructions to reset it.
        </p>

        <div class="mt-6 mb-2 text-left">
          <FormInput
            v-model.trim="email"
            type="email"
            :error="emailError"
            label="Email address"
          />
        </div>
        <div class="pt-3">
          <Button class="w-full py-3" @click.prevent="requestPasswordReset">
            Send password reset email
          </Button>
        </div>
        <p class="mt-4 text-xs">
          <RouterLink
            to="/users/sign-in"
            class="text-navy-300 hover:text-black"
          >
            Back to Sign in
          </RouterLink>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import Button from '~components/button/button.vue'
import FormInput from '~components/form/input.vue'

export default Vue.extend({
  components: {
    Button,
    FormInput,
  },

  data() {
    return {
      email: '',
      requested: false,
      newPassword: '',
      newPasswordConfirmation: '',
      showPasswordText: false,
    }
  },

  computed: {
    token(): any {
      return this.$route.query.reset_password_token
    },
    passwordError(): boolean {
      if ((this.newPassword || '').length < 3 || (this.newPasswordConfirmation || '').length < 3) {
        return false
      }

      return this.newPassword !== this.newPasswordConfirmation
    },
    emailError(): string {
      if (!this.email) {
        return ''
      }

      if (!/^\S+@\S+$/.test(this.email)) {
        return 'Enter a valid email address'
      }

      return ''
    },
  },

  methods: {
    async requestPasswordReset() {
      try {
        await this.axios.post('users/password', { user: { email: this.email } })
        this.requested = true
      } catch (err) {
        Sentry.captureException(err)
        throw err
      }
    },
    async submitNewPassword() {
      if (this.passwordError) {
        return false
      }

      try {
        await this.axios.put('users/password', {
          user: {
            password: this.newPassword,
            passwordConfirmation: this.newPasswordConfirmation,
            resetPasswordToken: this.token,
          },
        })
        return true
      } catch (err) {
        Sentry.captureException(err)
        throw err
      }
    },
  },
})
</script>
