<template>
  <div class="px-16 py-20">
    <div class="max-w-sm mx-auto">
      <div class="text-center pb-8">
        <h1 class="text-2xl pb-1 font-medium">
          Create account
        </h1>
        <p class="text-xs">
          <RouterLink
            to="/users/sign-in"
            class="text-navy-300 hover:text-black"
          >
            Already have an account?
          </RouterLink>
        </p>
      </div>

      <div class="mb-4">
        <FormInput
          v-model.trim="userData.company.value"
          :error="!!userData.company.error"
          label="Company name"
        />
      </div>
      <div class="mb-6 flex mt-0">
        <div class="w-1/2 pr-2">
          <FormInput
            v-model.trim="userData.firstName.value"
            :error="!!userData.firstName.error"
            label="First name"
          />
        </div>
        <div class="w-1/2 pl-2">
          <FormInput
            v-model.trim="userData.lastName.value"
            :error="!!userData.lastName.error"
            label="Last name"
          />
        </div>
      </div>
      <div class="mb-6">
        <FormInput
          v-model.trim="userData.email.value"
          :error="!!userData.email.error"
          type="email"
          label="Email address"
        />
      </div>
      <div class="mb-6">
        <FormInput
          v-model.trim="userData.password.value"
          type="password"
          :error="!!userData.password.error"
          label="Password"
        />
        <p v-if="userData.password.error" class="text-xs text-navy-300 mt-1">
          Password must be at least 8 characters long
        </p>
      </div>
      <div class="pt-1">
        <Button
          class="btn-primary w-full px-5 py-3"
          @click.prevent="submit"
        >
          Create account
        </Button>
      </div>
      <input
        v-model="userData.inviteToken.value"
        type="hidden"
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import * as _ from 'lodash'

import Button from '~components/button/button.vue'
import FormInput from '~components/form/input.vue'

export default Vue.extend({
  components: {
    Button,
    FormInput,
  },

  data() {
    const userFields = [
      'firstName',
      'lastName',
      'company',
      'email',
      'password',
      'inviteToken',
    ]
    const userData = userFields.reduce((fields, name) => (
      { ...fields, [name]: { value: undefined, touched: false, error: undefined } }
    ), {})

    return {
      userData,
      validInviteToken: false,
    }
  },

  computed: {
    userParams() {
      const formParams = _.mapValues(this.userData, 'value')
      return {
        ...formParams,
        passwordConfirmation: this.userData.password.value,
      }
    },
  },

  watch: {
    'userData.password.value': function (val) {
      if (val.length > 0 && val.length < 8) {
        this.userData.password.error = 'Passwords must be at least 8 characters'
      } else {
        this.userData.password.error = undefined
      }
    },
  },

  async mounted() {
    this.userData.inviteToken.value = this.$route.query.invite_token
    if (this.userData.inviteToken.value) {
      const { data } = await this.axios.get(`team_members/invite_info?invite_token=${this.userData.inviteToken.value}`)
      this.userData.email.value = data.email
      this.userData.company.value = data.account.company
      this.validInviteToken = true
    }
  },

  methods: {
    async submit() {
      try {
        const { data, headers } = await this.axios.post('/users', { user: this.userParams })
        this.$store.dispatch('auth/signupLogin', { data, headers })
        this.$router.push({ name: 'results' })
      } catch ({ response }) {
        window.test = response
        if (response.status === 422) {
          for (const [field, errors] of Object.entries(response.data)) {
            this.userData[field].error = errors[0]
          }
        }
      }
    },
  },
})
</script>
