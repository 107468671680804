<template>
  <div :class="variantClasses">
    <div class="flex-shrink-0 mr-4">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="variant === 'info'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.666 10a8.333 8.333 0 1116.667 0 8.333 8.333 0 01-16.667 0zm1.667 0a6.667 6.667 0 1013.333 0 6.667 6.667 0 00-13.333 0zm7.5.417a.417.417 0 00-.417-.417h-.833a.417.417 0 00-.417.417v2.5c0 .23.187.416.417.416h.833c.23 0 .417-.186.417-.416v-2.5zm-.417-3.75c.23 0 .417.186.417.416v.834c0 .23-.187.416-.417.416h-.833a.417.417 0 01-.417-.416v-.834c0-.23.187-.416.417-.416h.833z" fill="#0AAFFF"/></svg>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="variant === 'error'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.767 4.267l-2.942-2.942a1.667 1.667 0 00-1.183-.492H7.358c-.444 0-.87.177-1.183.492L3.233 4.267A2.5 2.5 0 002.5 6.033v4.875a6.667 6.667 0 002.575 5.259l3.4 2.65c.293.227.654.35 1.025.35h1c.371 0 .732-.123 1.025-.35l3.4-2.65a6.666 6.666 0 002.575-5.259V6.033a2.5 2.5 0 00-.733-1.766zm-5.934 8.65c0 .23-.186.416-.416.416h-.834a.417.417 0 01-.416-.416v-.834c0-.23.186-.416.416-.416h.834c.23 0 .416.186.416.416v.834zM10.442 10a.208.208 0 00.208-.183l.333-2.684a.417.417 0 00-.416-.466H9.433a.417.417 0 00-.416.466l.333 2.684a.208.208 0 00.208.183h.884z" fill="#EB5757"/></svg>
    </div>
    <slot />
  </div>
</template>

<script lang="js">
import Vue from 'vue'

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: 'info',
      validator: (variant) => ['info', 'error', 'success'].includes(variant),
    },
  },

  computed: {
    variantClasses() {
      return [
        'w-full inline-flex items-center relative rounded-md text-sm px-6 py-4',
        `alert--${this.variant}`,
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.alert--info {
  @apply border-blue bg-blue-100 text-blue;
}
.alert--error {
  @apply border-red bg-red-100 text-red;
}
.alert--success {
  @apply border-green bg-green-100 text-green;
}
</style>
