<template>
  <div class="px-16 py-10">
    <div class="container">
      <div class="flex">
        <ScoreSummary />

        <div class="md:w-3/5 m-2 sm:w-full">
          <h3 class="text-xl font-medium mb-8">
            All responses
          </h3>

          <div v-if="!hasLoaded" class="flex justify-center items-center h-screen">
            <Spinner />
          </div>

          <div v-if="replies && replies.length > 0">
            <ReplyRow
              v-for="reply in replies"
              :key="reply.id"
              :reply="reply"
            />
          </div>
          <div v-else>
            <p class="text-sm text-navy-300">
              Responses from all surveys will be shown here. Would you like to
              <RouterLink
                :to="{ name: 'survey', params: { id: 'new' } }"
                class="inline-flex items-center text-blue"
              >
                create new survey
              </RouterLink>?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import Spinner from '~components/spinner/spinner.vue'

import ReplyRow from './reply-row.vue'
import ScoreSummary from './score-summary.vue'

import { Reply } from '~models/reply'

export default Vue.extend({
  components: {
    Spinner,
    ReplyRow,
    ScoreSummary,
  },

  computed: {
    hasLoaded(): boolean {
      return this.$store.state.replies !== undefined
    },
    replies(): Reply[] {
      return this.$store.state.replies.replies
    },
  },

  created() {
    if (this.$store.getters['auth/loggedIn'] && this.$store.state.surveys.surveys === undefined) {
      this.$store.dispatch('surveys/fetchAll')
    }
    this.$store.dispatch('replies/fetch')
  },
})
</script>
