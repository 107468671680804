<template>
  <label :for="id" class="flex items-center mb-6">
    <input
      :id="id"
      :value="value"
      type="checkbox"
      :name="id"
      hidden
      @change="$emit('change', !value)"
    >

    <span
      role="checkbox"
      tabindex="0"
      aria-checked="false"
      :aria-labelledby="id"
      class="bg-gray-200 relative inline-flex flex-shrink-0 border-2 h-4 w-6 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-100 focus:outline-none"
      :class="{ 'bg-blue': value }"
    >
      <span
        aria-hidden="true"
        class="translate-x-0 inline-block h-3 w-3 rounded-full bg-white shadow transform transition ease-in-out duration-200"
        :class="{ 'translate-x-2': value }"
      />
    </span>
    <span :id="id" class="text-sm text-navy-800 ml-3 cursor-pointer">
      {{ label }}
    </span>
  </label>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
})
</script>
