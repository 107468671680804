<template>
  <div class="w-2/5 m-2 pr-6">
    <h3 class="text-lg font-medium mb-8">
      Surveys
    </h3>

    <div v-if="!hasLoaded" class="flex justify-center items-center h-screen">
      <Spinner />
    </div>

    <div v-else class="flex flex-col pr-8">
      <RouterLink
        :to="{ name: 'results'}"
        class="my-1 rounded-xl hover:bg-bg-100 hover:text-blue transform tranisiton duration-75"
      >
        <div class="flex items-center pl-5">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'inline filter-remove outline-none mx-1'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 5.76l3.11 3.11A3 3 0 0123 11v6a3 3 0 01-3 3h-4.76a2 2 0 01-1.79-1.11l-.17-.34a1 1 0 00-.9-.55h-.76a1 1 0 00-.9.55l-.17.34A2 2 0 018.76 20H4a3 3 0 01-3-3v-6a3 3 0 01.89-2.13L5 5.76A6 6 0 019.24 4h5.52A6 6 0 0119 5.76zM9.24 6a4 4 0 00-2.82 1.17L5.59 8h12.82l-.83-.83A4 4 0 0014.76 6H9.24zM21 17a1 1 0 01-1 1h-4.76l-.45-.89A2 2 0 0013 16h-2a2 2 0 00-1.79 1.11l-.45.89H4a1 1 0 01-1-1v-6a1 1 0 011-1h16a1 1 0 011 1v6zM5.5 12h13a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5z" fill="#8592A3"/></svg>
          <p class="text-sm truncate leading-tight p-4">
            All responses
          </p>
        </div>
      </RouterLink>
      <RouterLink
        v-for="survey in surveys"
        :key="survey.id"
        :to="{ name: 'surveyResults', params: { surveyId: survey.id } }"
        class="flex items-center my-1 p-3 rounded-xl hover:bg-bg-100 hover:text-blue transform tranisiton duration-75"
      >
        <ScoreCircle
          class="relative flex-shrink-0 w-10.5 text-text"
          size="small"
          :total-score="survey.opportunityScore || 0"
        />
        <div class="ml-3 w-full flex justify-between pr-2 items-center">
          <div class="w-4/5">
            <p class="text-sm truncate leading-tight">
              {{ survey.name }}
            </p>
            <p class="block text-xs text-navy-300 truncate">
              {{ survey.replyCount }} replies
            </p>
          </div>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import ScoreCircle from './score-circle.vue'

import { SurveyWithStats } from '~models/survey'

export default Vue.extend({
  components: {
    ScoreCircle,
  },

  computed: {
    hasLoaded(): boolean {
      return this.$store.state.surveys !== undefined
    },
    surveys(): SurveyWithStats[] {
      return this.$store.state.surveys.surveys
    },
  },
})
</script>

<style lang="scss" scoped>
  .router-link-exact-active {
    font-weight: 400;
    background-color: #F9FAFC;
  }
</style>
