<template>
  <div>
    <svg
      viewBox="0 0 114 114"
      :width="sizeInPx"
      :height="sizeInPx"
      fill="none"
    >
      <circle
        r="50"
        cx="57"
        cy="57"
        stroke="#E5E9F1"
        stroke-width="8"
      />
      <circle
        :stroke="progressColor"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashOffset"
        class="progress"
        transform="rotate(-90 57 57)"
        r="50"
        cx="57"
        cy="57"
        stroke-width="8"
        stroke-linecap="round"
      />
    </svg>
    <div
      :class="{'text-xl': size === 'large'}"
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center font-medium leading-none text-sm"
    >
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    totalScore: {
      type: Number,
      required: true,
      validator: (val: number): boolean => val >= 0 && val <= 10,
    },
    size: {
      type: String,
      required: false,
      default: 'large',
      validator: (size: string): boolean => ['small', 'large'].includes(size),
    },
  },

  computed: {
    progressColor(): string {
      if (this.totalScore <= 4) {
        return '#2CCC80'
      }

      if (this.totalScore <= 6) {
        return '#FFC112'
      }

      if (this.totalScore <= 8) {
        return '#FF7D51'
      }

      return '#EB5757'
    },
    circumference(): number {
      return Math.PI * 100
    },
    dashOffset(): number {
      return this.circumference * (1 - (this.totalScore / 10))
    },
    sizeInPx(): number {
      if (this.size === 'large') {
        return 76
      }

      return 42
    },
    label(): number {
      return Math.round((this.totalScore + Number.EPSILON) * 10) / 10
    },
  },
})
</script>
