<template>
  <div v-on-clickaway="hideDropdown" class="relative z-10 dropdownButton w-full max-w-50">
    <div class="flex items-center cursor-pointer text-sm border border-blue-border-100 rounded-md leading-none px-4 py-3" @click.prevent="isOpen = !isOpen">
      <div class="mr-3">
        {{ selectedOption.formatted }}
      </div>
      <div class="ml-auto">
        <svg width="8" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#8592A3" d="M.206 1.138a.458.458 0 010-.65L.389.304A.45.45 0 01.72.167h6.564a.45.45 0 01.33.137l.183.184a.458.458 0 010 .65L4.322 4.613a.44.44 0 01-.642 0L.206 1.138z"/></svg>
      </div>
    </div>
    <div class="dropdown w-full absolute right-0 mt-1 p-2 bg-white border border-blue-border-100 rounded-md text-sm shadow" :class="{hidden: !isOpen}">
      <div
        v-for="option in options"
        :key="option.value"
        class="flex items-center px-3 py-2 leading-tight text-gray-800 rounded-sm cursor-pointer hover:text-white hover:bg-blue"
        @click.prevent="select(option.value)"
      >
        {{ option.formatted }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mixin as clickaway } from 'vue-clickaway'

export type Option = {
  value: string | number
  formatted: string
}

export default Vue.extend({
  mixins: [
    clickaway,
  ],

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
  },

  data(): {
    isOpen: boolean
  } {
    return {
      isOpen: false,
    }
  },

  computed: {
    selectedOption(): Option {
      return this.options.find((option: Option) => option.value === this.value) || this.options[0]
    },
  },

  methods: {
    select(value: string | number): void {
      this.$emit('input', value)
      this.isOpen = false
    },
    hideDropdown(): void {
      this.isOpen = false
    },
  },
})
</script>
