<template>
  <div class="fixed z-50 left-0 top-0 w-full h-full overflow-hidden bg-bg-200">
    <div class="flex items-center justify-center flex-wrap absolute left-0 top-0 w-full h-full p-5 overflow-x-auto">
      <div class="relative w-125 bg-white shadow rounded-md">
        <div class="flex items-center h-15 border-b border-blue-border-100 text-sm font-medium px-4 leading-tight">
          {{ title }}
        </div>

        <div class="px-4 py-6">
          <slot />

          <div
            v-if="$slots.action || showCancelButton"
            class="flex justify-end pt-6"
          >
            <Button
              v-if="showCancelButton"
              class="py-2.5 px-4 mr-4"
              variant="outline"
              @click="$emit('close')"
            >
              {{ cancelButtonText }}
            </Button>

            <slot name="action" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Button from '~components/button/button.vue'

export default Vue.extend({
  components: {
    Button,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
  },

  mounted() {
    document.addEventListener('keyup', this.listenForEscapeKey, { capture: true })
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.listenForEscapeKey, { capture: true })
  },

  methods: {
    listenForEscapeKey(e: KeyboardEvent) {
      if (!this.showCancelButton) {
        return
      }

      const key = e.key || e.keyCode

      if (key === 'Escape' || key === 'Esc' || key === 27) {
        e.preventDefault()
        e.stopPropagation()

        this.$emit('close')
      }
    },
  },
})
</script>
