<template>
  <div class="px-16 py-20">
    <div class="container-2">
      <Navigation />
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import Vue from 'vue'

import Navigation from './navigation.vue'

export default Vue.extend({
  components: {
    Navigation,
  },
})
</script>
