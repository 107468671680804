export enum ENVIRONMENTS {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const getCurrentEnvironment = (): ENVIRONMENTS => {
  if (window.location.hostname === 'app.exakt.io') {
    return ENVIRONMENTS.PRODUCTION
  }

  if (window.location.hostname === 'app.exakt.dev') {
    return ENVIRONMENTS.STAGING
  }

  return ENVIRONMENTS.DEVELOPMENT
}
