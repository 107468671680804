<template>
  <div class="px-16 pb-20 pt-10">
    <div class="container-2">
      <Navigation />

      <div v-if="!showInviteEmailForm" class="mb-8">
        <Button
          variant="outline"
          class="flex items-center"
          @click.prevent="showInviteEmailForm = true"
        >
          <div class="flex-shrink-0 mr-2">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'outline-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.168 9.167a2.5 2.5 0 10-.75-4.884 4.167 4.167 0 010 4.767 2.5 2.5 0 00.75.117zm-4.166 0a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm4.908 4.625A.833.833 0 019.168 15H.835a.833.833 0 01-.742-1.208l.833-1.584a2.5 2.5 0 012.192-1.375h3.767a2.5 2.5 0 012.233 1.375l.792 1.584zM17.5 8.333h2.084c.23 0 .416.187.416.417v.833c0 .23-.186.417-.416.417H17.5v2.083c0 .23-.186.417-.416.417h-.834a.417.417 0 01-.416-.417V10H13.75a.417.417 0 01-.416-.417V8.75c0-.23.186-.417.416-.417h2.084V6.25c0-.23.186-.417.416-.417h.834c.23 0 .416.187.416.417v2.083zm-6.45 2.5a2.5 2.5 0 012.234 1.375l.791 1.584A.833.833 0 0113.335 15h-1.817a2.5 2.5 0 00-.108-1.958l-.792-1.575a4.077 4.077 0 00-.4-.634h.833z" fill="#8592A3"/></svg>
          </div>
          Invite
        </Button>
      </div>

      <div v-if="showInviteEmailForm" class="mb-8">
        <Alert v-if="error" variant="error">
          {{ error }}
        </Alert>

        <form class="mb-6" @submit.prevent="inviteTeamMember">
          <FormInput
            v-model="inviteEmail"
            type="email"
            placeholder="Email address"
            class="mb-1"
            :class="{error: fieldErrors.inviteEmail}"
            refs="inviteEmail"
          />
          <p class="text-xs text-navy-300 mb-4">
            Enter one or multiple emails separated by comma.
          </p>
          <div class="flex">
            <Button
              class="btn-primary px-4 py-3 mr-4"
              @click.prevent="inviteTeamMember"
            >
              Send invitation
            </Button>
            <Button
              variant="outline"
              class="flex items-center"
              @click.prevent="showInviteEmailForm = false"
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>

      <div class="pb-16">
        <div v-if="teamMembers.length > 0" class="text-sm">
          <div class="flex pb-5">
            <div class="text-navy-300 leading-none w-1/2">
              Name
            </div>
            <div class="text-navy-300 leading-none w-1/2">
              Email
            </div>
            <div class="w-6 flex-shrink-0" />
          </div>

          <div
            v-for="teamMember in teamMembers"
            :key="teamMember.id"
            class="leading-none w-full flex items-center mb-6"
          >
            <div class="flex items-center w-1/2">
              <img
                v-if="teamMember.avatarUrl !== ''"
                :src="teamMember.avatarUrl"
                :alt="teamMember.fullName"
                class="w-6 h-6 rounded-full mr-4"
                @error="teamMember.avatarUrl = ''"
              >
              <span v-else class="w-6 h-6 rounded-full mr-4 block bg-gray-100" />
              <template v-if="teamMember.fullName">
                {{ teamMember.fullName }}
              </template>
              <span v-else class="text-gray-200">
                {{ teamMember.email }}
              </span>
            </div>
            <div class="w-1/2">
              {{ teamMember.email }}

              <Badge v-if="teamMember.status === UserStatus.INVITED">
                Invited
              </Badge>
            </div>
            <div class="w-6 flex-shrink-0 flex items-center justify-center">
              <Button
                v-if="currentUser.role === UserRole.ADMIN && teamMember.id !== currentUser.id"
                variant="outline"
                class="flex items-center"
                @click.prevent="removeTeamMember(teamMember)"
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import Vue from 'vue'

import { UserRole, UserStatus } from '~models/user'

import Navigation from './navigation.vue'
import Alert from '~components/alert/alert.vue'
import Badge from '~components/badge/badge.vue'
import Button from '~components/button/button.vue'
import FormInput from '~components/form/input.vue'

export default Vue.extend({
  components: {
    Navigation,
    Alert,
    Badge,
    Button,
    FormInput,
  },

  data() {
    return {
      UserRole,
      UserStatus,
      error: null,
      fieldErrors: {
        inviteEmail: false,
      },
      inviteEmail: null,
      teamMembers: [],
      showInviteEmailForm: false,
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },

  mounted() {
    this.fetchTeamMembers()
  },

  methods: {
    async fetchTeamMembers() {
      try {
        const { data } = await this.axios.get('team_members')
        this.teamMembers = data
      } catch (err) {
        Sentry.captureException(err)
        throw err
      }
    },

    async inviteTeamMember() {
      if (this.inviteEmail.length < 6) {
        this.$refs.inviteEmail.$el.focus()
        return
      }

      try {
        await this.axios.post('team_members', { email: this.inviteEmail })
        this.inviteEmail = null
        this.showInviteEmailForm = false
        this.fetchTeamMembers()
      } catch (err) {
        this.error = `There was an error inviting ${this.inviteEmail}`
        this.fieldErrors.inviteEmail = true
        throw err
      }
    },

    async removeTeamMember(teamMember) {
      try {
        await this.axios.delete(`team_members/${teamMember.id}`)
        this.fetchTeamMembers()
      } catch {
        // NOOP
      }
    },
  },
})
</script>
