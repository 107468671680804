<template>
  <div class="px-16 py-20">
    <div class="max-w-xs mx-auto">
      <div class="text-center pb-8">
        <h1 class="text-2xl pb-1 font-medium">
          Sign in
        </h1>
      </div>

      <div class="mb-4">
        <FormInput
          v-model.trim="userData.email"
          type="email"
          label="Email address"
          tabindex="1"
        />
      </div>
      <div class="mb-4">
        <FormInput
          v-model.trim="userData.password"
          type="password"
          label="Password"
          tabindex="2"
          @keyup.enter="submit"
        />
        <p class="text-xs mt-1" tabindex="4">
          <RouterLink
            to="/password-reset"
            class="text-navy-300 hover:text-blue"
          >
            Forgot password?
          </RouterLink>
        </p>
      </div>

      <div v-if="loginError">
        <p class="text-xs text-red text-center my-4 py-4 bg-red-100 rounded-md">
          Incorrect credentials. Please try again!
        </p>
      </div>

      <div class="pt-1">
        <Button
          class="btn-primary w-full px-5 py-3"
          @click.prevent="submit"
          tabindex="3"
        >
          Sign in
        </Button>
      </div>

      <p class="text-center text-xs mt-6 py-4 border border-blue-border-100 rounded-md">
        New to Exakt?
        <RouterLink
          to="/users/sign-up"
          class=" text-blue hover:text-blue"
        >
          Create an account.
        </RouterLink>
      </p>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue'

import Button from '~components/button/button.vue'
import FormInput from '~components/form/input.vue'

export default Vue.extend({
  components: {
    Button,
    FormInput,
  },

  data() {
    return {
      userData: {
        email: undefined,
        password: undefined,
      },
      loginError: undefined,
    }
  },

  methods: {
    async submit() {
      this.loginError = undefined

      try {
        await this.$store.dispatch('auth/login', this.userData)
        await this.$store.dispatch('billing/fetchBilling')
      } catch (err) {
        this.loginError = err.response
      }
    },
  },
})
</script>
