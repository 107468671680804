<template>
  <router-link
    v-if="href || to"
    :to="href || to"
    :class="buttonClasses"
    :disabled="isDisabled"
    class="transition duration-75 ease-in-out px-4 py-2"
    v-on="$listeners"
    @click.native="scrollFix(href)"
  >
    <slot />
  </router-link>

  <button
    v-else
    :class="buttonClasses"
    :disabled="isDisabled"
    class="transition duration-75 ease-in-out px-4 py-2"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script lang="js">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'danger', 'outline', 'success'].includes(v),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    to: {
      type: Object as PropType<{ name: string, query: Record<string, string> }>,
      default: undefined,
    },
    icon: {
      type: String,
      default: '',
    },
  },

  computed: {
    buttonClasses() {
      return `btn btn-${this.variant}`
    },
    isDisabled() {
      return this.disabled || this.loading
    },
    toHashtag() {
      return this.href.charAt(0) === '#'
    },
  },

  methods: {
    // So that second use of the same hashtag triggers the url change (https://forum.vuejs.org/t/how-to-handle-anchors-bookmarks-with-vue-router/14563)
    scrollFix(hashtag) {
      if (this.toHashtag) {
        const element: HTMLElement | null = document.querySelector(hashtag)
        if (element) {
          window.scrollTo(0, element.offsetTop - 90)
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>

.btn-primary {
  @apply text-sm leading-none rounded-md border-0 bg-blue outline-none text-white;
}

.btn-primary:hover {
  background-color: #00a3f5;
}

.btn-primary:focus,
.btn-primary:active, {
  background-color: #0096e0;
}

.btn-danger {
  @apply text-sm leading-none rounded-md border-0 bg-red outline-none text-white;
}

.btn-danger:hover {
  background-color: #ea4848;
}

.btn-danger:focus,
.btn-danger:active, {
  background-color: #e73636;
}

.btn-success {
  @apply text-sm leading-none rounded-md border-0 bg-green outline-none text-white;
}

.btn-success:hover {
  background-color: #2ecc80;
}

.btn-success:focus,
.btn-success:active, {
  background-color: #2ecc80;
}

.btn-outline {
  @apply text-sm font-medium leading-none rounded-md border border-blue-border-100 outline-none text-navy-800;
}

.btn-outline:hover {
  background-color: #F9FAFC;
  color: #1F2D3F;
}

.btn-outline:focus,
.btn-outline:active, {
  background-color: #F9FAFC;
  color: #1F2D3F;
}

.btn[disabled] {
  &,
  &:hover,
  &:focus,
  &:active {
    @apply border-blue-200 bg-blue-200 cursor-not-allowed;
  }
}
</style>
