/* eslint-disable import/no-cycle */
import Vue from 'vue'
import VueRouter from 'vue-router'

import ResultsPage from '~pages/results/results.vue'
import SurveyResultsPage from '~pages/results/survey-results.vue'
import SurveysPage from '~pages/surveys/surveys.vue'
import SurveyPage from '~pages/survey/survey.vue'
import WidgetPage from '~pages/widget/widget.vue'
import PersonalSettingsPage from '~pages/settings/personal.vue'
import BusinessSettingsPage from '~pages/settings/business.vue'
import TeamSettingsPage from '~pages/settings/team.vue'
import BillingPage from '~pages/settings/billing.vue'
import PaymentPage from '~pages/settings/billing/payment.vue'
import SignUpPage from '~pages/sign-up.vue'
import SignInPage from '~pages/sign-in.vue'
import PasswordResetPage from '~pages/password-reset.vue'
import NotFound from '~pages/not-found.vue'

import { BillingStatus } from '~models/billing-status'

import store from '~plugins/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/', redirect: '/results',
    },
    {
      path: '/results', component: ResultsPage, name: 'results', meta: { requiresAuth: true },
    },
    {
      path: '/results/:surveyId', component: SurveyResultsPage, name: 'surveyResults', meta: { requiresAuth: true }, props: true,
    },
    {
      path: '/surveys', component: SurveysPage, name: 'surveys', meta: { requiresAuth: true },
    },
    {
      path: '/surveys/:id', component: SurveyPage, name: 'survey', props: true, meta: { requiresAuth: true },
    },
    {
      path: '/widget', component: WidgetPage, name: 'widget', meta: { requiresAuth: true },
    },
    {
      path: '/settings/personal', component: PersonalSettingsPage, name: 'personal-settings', meta: { requiresAuth: true },
    },
    {
      path: '/settings/business', component: BusinessSettingsPage, name: 'business-settings', meta: { requiresAuth: true },
    },
    {
      path: '/settings/team', component: TeamSettingsPage, name: 'team-settings', meta: { requiresAuth: true },
    },
    {
      path: '/settings/billing', component: BillingPage, name: 'billing', meta: { requiresAuth: true },
    },
    {
      path: '/settings/payment', component: PaymentPage, name: 'payment', meta: { requiresAuth: true },
    },
    {
      path: '/users/sign-up', component: SignUpPage, name: 'sign-up', meta: { hideAppNavigation: true, logoHeader: true },
    },
    {
      path: '/users/sign-in', component: SignInPage, name: 'sign-in', meta: { hideAppNavigation: true, logoHeader: true },
    },
    {
      path: '/password-reset', component: PasswordResetPage, name: 'password-reset', meta: { hideAppNavigation: true, logoHeader: true },
    },
    {
      path: '*', component: NotFound, name: 'notFound',
    },
  ],
})

// Auth guard
router.beforeEach(async (to, from, next) => {
  store.dispatch('auth/authCheck')

  if (!to.matched.some(({ meta }) => meta.requiresAuth)) {
    return next()
  }

  if (!store.getters['auth/loggedIn']) {
    return next({ name: 'sign-in' })
  }

  if (store.getters['auth/loggedIn']) {
    if (!store.getters['billing/hasLoaded']) {
      await store.dispatch('billing/fetchBilling')
    }

    if (store.getters['billing/billingStatus'] === BillingStatus.TRIAL_EXPIRED && to.name !== 'payment') {
      return next({ name: 'payment' })
    }
  }

  return next()
})

export default router
