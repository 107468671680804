/* eslint-disable import/no-cycle */

import Vue from 'vue'
import Vuex, { ActionContext } from 'vuex'

import auth, { State as AuthState } from '../store/auth.module'
import billing, { State as BillingState } from '../store/billing.module'
import surveys, { State as SurveysState } from '../store/surveys.module'
import replies, { State as RepliesState } from '../store/replies.module'
import widget, { State as WidgetState } from '../store/widget.module'

Vue.use(Vuex)

export interface RootState {
  auth: AuthState,
  billing: BillingState,
  surveys: SurveysState,
  replies: RepliesState,
  widget: WidgetState,
}

export type ModuleContext<T> = ActionContext<T, RootState>;

const store = new Vuex.Store({
  modules: {
    auth,
    billing,
    surveys,
    replies,
    widget,
  },
})

export default store
